import { AxiosResponse } from 'axios';
import PQueue from 'p-queue';

import { carmaClientLegacy } from 'src/services/instance/carmaClientLegacy';
import { getOr } from 'src/utils/getOr';

import { CheckoutApiPayload, CheckoutApiResponse, CheckoutApiUpdateVehiclePayload } from './CheckoutApi.types';

const apiCreateCheckoutQueue = new PQueue({ concurrency: 1 });

/**
 * Retrieve the current user cart
 */
export const getCheckouts = () =>
  apiCreateCheckoutQueue.add(() =>
    carmaClientLegacy
      .get<CheckoutApiResponse[]>(`/me/checkouts`)
      .then((body) => body.data)
      .catch((_error) => null),
  );

/**
 * Create a cart
 * @param sku The vehicle SKU
 */
export const createCheckout = (sku: string) =>
  apiCreateCheckoutQueue.add(() =>
    carmaClientLegacy
      .post<any, AxiosResponse<CheckoutApiResponse>, CheckoutApiPayload>(`/me/checkouts`, {
        vehicle: {
          sku,
        },
      })
      .then((body) => body.data),
  );

/**
 * Replace the car in the cart
 * @param cartId The cart ID
 * @param sku The vehicle SKU
 */
export const switchVehicleCheckout = (cartId: string, sku: string) =>
  carmaClientLegacy
    .patch<any, AxiosResponse<CheckoutApiResponse>, CheckoutApiUpdateVehiclePayload>(
      `/me/checkouts/${cartId}/vehicle`,
      {
        sku,
      },
    )
    .then((body) => body.data);

/**
 * Update a cart
 * @param cartId The cart ID
 * @param payload The data to pass to update the cart
 */
export const updateCheckout = (cartId: string, payload: CheckoutApiPayload) =>
  carmaClientLegacy
    .patch<any, AxiosResponse<CheckoutApiResponse>, CheckoutApiPayload>(`/me/checkouts/${cartId}`, payload)
    .then((body) => body.data);

export const handleUpdateCheckoutError = (error: any): string => {
  let errorMessage = 'An unknown error happened, please try again later';
  if (error.response) {
    errorMessage = getOr(error.response.data, 'detail[0].msg', getOr(error.response.data, 'detail', errorMessage));
  }
  return errorMessage;
};
