import Link from 'next/link';
import { Components } from 'react-markdown';

import styles from './Markdown.module.scss';

export const MarkdownLink: Components['a'] = ({ children, href, node, ...props }) => (
  <Link className={styles.link} href={href ?? '/'} {...props}>
    {Array.isArray(children) ? children[0] : children}
  </Link>
);

export const MarkdownLinkBlank: Components['a'] = ({ children, href, node, ...props }) => (
  <Link className={styles.link} href={href ?? '/'} target="_blank" {...props}>
    {Array.isArray(children) ? children[0] : children}
  </Link>
);
