import { clone } from 'lodash';

import { SAVED_SEARCH_DEFAULT_NAME, SavedSearch } from 'src/data/SavedSearchApi/SavedSearchApi';
import { FiltersPayloadRow, PlpFilterQueryParams } from 'src/types/CarFilters.types';

/**
 * Create optimistic data for the saved searches
 * @param savedSearches The current saved searches
 * @param savedSearch If set, the existing saved search to update
 * @param isActive true if optimistic saved search should be active or not
 * @param plpFilters the filters for that saved search
 * @returns the optimistic version value of the saved searches
 */
export const getOptimisticSavedSearches = (
  savedSearches: SavedSearch[],
  savedSearch: SavedSearch | null,
  isActive: boolean,
  plpFilters: PlpFilterQueryParams | FiltersPayloadRow[],
): SavedSearch[] => {
  let newSavedSearches = clone(savedSearches);
  const newSubscribed = isActive ? new Date('2023-01-01T00:00:00.000Z').toISOString() : null;

  const newSearchItem: SavedSearch = clone(savedSearch) ?? {
    id: 'tmp-id',
    name: SAVED_SEARCH_DEFAULT_NAME,
    subscribed_at: newSubscribed,
    filters: Array.isArray(plpFilters) ? plpFilters : plpFilters.filters,
  };

  if (savedSearch) {
    // Update the new saved search
    newSearchItem.subscribed_at = newSubscribed;
    // Remove the previous version of the savedSearch from the array (new version will be added as push)
    newSavedSearches = newSavedSearches.filter((item) => item.id !== savedSearch.id);
  }

  newSavedSearches.push(newSearchItem);

  return newSavedSearches;
};
