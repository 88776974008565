'use client';

import { useReportWebVitals } from 'next/web-vitals';
import { FC } from 'react';

import { pushToDataLayer } from 'src/utils/pushToDataLayer';

export const WebVitals: FC<{}> = () => {
  useReportWebVitals((metric) => {
    pushToDataLayer({
      event: 'webVitals',
      category: metric.name,
      value: metric.value,
      navigationType: metric.navigationType,
      url: window.location.toString(),
    });
  });
  return null;
};
