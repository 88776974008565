import { produce } from 'immer';
import { FC, ReactNode, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { CAR_PURCHASE_STATUS } from 'src/constants';

import { storeFactory } from './storeFactory';

export type CarStatusStoreStateData = {
  unavailableCarsData: { [sku: string]: CAR_PURCHASE_STATUS };
};

export type CarStatusStoreStateFunction = {
  setUnavailableCarsData: (unavailableCarsData: CarStatusStoreStateData['unavailableCarsData']) => void;
};

const {
  store: carStatusStore,
  StoreProvider: CarStatusStoreProvider,
  useCreateStore: useCreateCarStatusStore,
} = storeFactory<CarStatusStoreStateData, CarStatusStoreStateFunction>(
  () => ({
    unavailableCarsData: {},
  }),
  (set) => ({
    setUnavailableCarsData: (unavailableCarsData: CarStatusStoreStateData['unavailableCarsData']) => {
      set((state) =>
        produce(state, (draft) => {
          draft.unavailableCarsData = unavailableCarsData;
        }),
      );
    },
  }),
);
export { carStatusStore, CarStatusStoreProvider, useCreateCarStatusStore };

export const CarStatusStore: FC<{ children: ReactNode }> = ({ children }) => {
  const createCarStatusStore = useCreateCarStatusStore({});

  return <CarStatusStoreProvider createStore={createCarStatusStore}>{children}</CarStatusStoreProvider>;
};

export const CarStatusContextSetUnavailableCarsData: FC<Pick<CarStatusStoreStateData, 'unavailableCarsData'>> = ({
  unavailableCarsData,
}) => {
  const { setUnavailableCarsData } = carStatusStore(
    (state) => ({
      setUnavailableCarsData: state.setUnavailableCarsData,
    }),
    shallow,
  );

  useEffect(() => {
    setUnavailableCarsData(unavailableCarsData);
  }, [unavailableCarsData]);

  return null;
};
