import { visit } from 'unist-util-visit';

const supportedCustomDirectives = ['a', 'quote', 'img', 'video', 'instagram', 'carousel'];

export const customDirective: import('unified').Plugin<[], import('mdast').Root> = () => {
  return (tree) => {
    visit(tree, (node) => {
      if (node.type === 'leafDirective' || node.type === 'containerDirective' || node.type === 'textDirective') {
        if (supportedCustomDirectives.indexOf(node.name) < 0) {
          return;
        }

        const data = node.data || (node.data = {});
        const attributes = node.attributes || {};

        data.hName = node.name;
        data.hProperties = attributes;
      }
    });
  };
};
