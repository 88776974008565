import { cloneDeep } from 'lodash';
import qs from 'query-string';

import {
  PageProductListingFilters,
  PageProductListingOptions,
} from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import { SortValues } from 'src/types/CarFilters.types';

export const plpStoreFiltersToUrlParams = (
  plpStoreFilters: PageProductListingFilters,
  plpStoreListingOptions: PageProductListingOptions = {},
  whitelistedParams: Record<string, any> = {},
): string | undefined => {
  // Sort the filters to return idempotent URL params
  const sortedFilters = cloneDeep({ ...plpStoreFilters, ...plpStoreListingOptions });
  const arrayParamsKeys: Array<KeysMatching<PageProductListingFilters, any[]>> = [
    'makeModel',
    'transmission',
    'fuelType',
    'highlightedFeatures',
    'bodyType',
    'seats',
    'doors',
    'colour',
  ];
  arrayParamsKeys.forEach((key) => {
    if (sortedFilters[key]) {
      sortedFilters[key]!.sort();
    }
  });

  // Special case for page 1
  if (sortedFilters['page'] === '1') {
    delete sortedFilters['page'];
  }

  // Special case for sortBy recommended
  if (sortedFilters['sortBy'] === SortValues.Recommended) {
    delete sortedFilters['sortBy'];
  }

  const params = { ...sortedFilters, ...whitelistedParams };

  const result = qs.stringify(params, { arrayFormat: 'bracket', skipEmptyString: true });

  return result !== '' ? result : undefined;
};
