'use client';

import { produce } from 'immer';
import { FC, ReactNode, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { CheckoutApiResponse } from 'src/data/CheckoutApi/CheckoutApi.types';
import { BaseCarData } from 'src/types/Car.types';

import { storeFactory } from './storeFactory';

interface CartStoreStateData {
  carData: BaseCarData | null;
  carLoaded: boolean;
  apiCartData: CheckoutApiResponse | null;
  apiCartLoaded: boolean;
  cartCarSku: string | null;
  cartExpiry: Date | null;
  cartExpired: boolean;
}

interface CartStoreStateFunctions {
  setApiCartDataLoading: () => void;
  setCarDataLoading: () => void;
  setApiCartData: (apiCartData: CheckoutApiResponse | null) => void;
  setCarData: (carData: BaseCarData | null) => void;
  /** Empty the cart data. To be used when user logs out for example */
  clearCartData: () => void;
}

const {
  store: cartStore,
  StoreProvider: CartStoreProvider,
  useCreateStore: useCreateCartStore,
} = storeFactory<CartStoreStateData, CartStoreStateFunctions>(
  () => ({
    carData: null,
    carLoaded: false,
    apiCartData: null,
    apiCartLoaded: false,
    cartCarSku: null,
    cartExpiry: null,
    cartExpired: true,
  }),
  (set, get) => ({
    setApiCartDataLoading: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.apiCartLoaded = false;
          draft.cartExpired = false;
        }),
      );
    },
    setCarDataLoading: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.carLoaded = false;
        }),
      );
    },
    setApiCartData: (apiCartData: CheckoutApiResponse | null) => {
      set((state) =>
        produce(state, (draft) => {
          draft.apiCartData = apiCartData;
          draft.apiCartLoaded = true;
          // Set cart expired if needed
          const cartExpiry = apiCartData?.expiry
            ? new Date(`${apiCartData.expiry}Z`) // Add UTC formatting to the date
            : null;
          const now = new Date();
          draft.cartExpiry = cartExpiry;
          draft.cartExpired =
            apiCartData === null ||
            (apiCartData !== null && apiCartData.vehicle.sku === null) ||
            (cartExpiry !== null && cartExpiry.getTime() < now.getTime());

          if (apiCartData === null) {
            // Reset the car SKU if needed
            draft.cartCarSku = null;
          } else {
            draft.cartCarSku = apiCartData.vehicle.most_recently_added_sku;
          }

          // Reset cartData if change of SKU
          if (get().cartCarSku !== draft.cartCarSku) {
            draft.carData = null;
            draft.carLoaded = false;
          }
        }),
      );
    },
    setCarData: (cartData: BaseCarData | null) => {
      set((state) =>
        produce(state, (draft) => {
          draft.carData = cartData;
          draft.carLoaded = true;
        }),
      );
    },
    clearCartData: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.carData = null;
          draft.carLoaded = false;
          draft.apiCartData = null;
          draft.apiCartLoaded = false;
          draft.cartCarSku = null;
          draft.cartExpiry = null;
          draft.cartExpired = false;
        }),
      );
    },
  }),
);

export { cartStore, CartStoreProvider, useCreateCartStore };

export const CartStore: FC<{ children: ReactNode }> = ({ children }) => {
  const createCartStore = useCreateCartStore({});

  return <CartStoreProvider createStore={createCartStore}>{children}</CartStoreProvider>;
};

export const CartContextSetCartData: FC<Pick<CartStoreStateData, 'apiCartData'>> = ({ apiCartData }) => {
  const { setApiCartData } = cartStore(
    (state) => ({
      setApiCartData: state.setApiCartData,
    }),
    shallow,
  );

  useEffect(() => {
    setApiCartData(apiCartData);
  }, [apiCartData]);

  return null;
};
