import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { Button } from 'src/general/components/Button/Button';
import { Image } from 'src/general/components/Image/Image';

import styles from './WholePageMessage.module.scss';

interface WholePageMessageProps {
  iconSrc: string;
  title: ReactNode;
  message?: ReactNode;
  buttonLabel: ReactNode;
  buttonLink?: string;
  buttonOnClick?: () => void;
  className?: string;
  fullHeight?: boolean;
}

/**
 * Display a whole-page message to the user, with a button to navigate them away.
 */
export const WholePageMessage: FC<WholePageMessageProps> = ({
  iconSrc,
  title,
  message,
  buttonLabel,
  buttonLink,
  buttonOnClick,
  className,
  fullHeight,
}) => (
  <section className={classNames(styles.root, { [styles.fullHeight]: fullHeight }, className)}>
    <Image src={iconSrc} width={240} height={240} alt="" />
    <div className={styles.content}>
      {typeof title === 'string' ? <h1 className="c-fs-h3 cfw-bold title">{title}</h1> : title}
      {!!message && (typeof message === 'string' ? <p className="c-fs-h6 message">{message}</p> : message)}
    </div>
    <Button href={buttonLink} onClick={buttonOnClick}>
      {buttonLabel}
    </Button>
  </section>
);
