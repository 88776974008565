import { get as _get } from 'lodash';

/**
 * Used for lodash deep path
 * Modified from: https://github.com/Pouja/typescript-deep-path-safe
 * Related article: https://dev.to/pouja/making-lodash-function-get-type-safe-313o
 */
type ResolveType<ObjectType, Path extends string, OrElse> = Path extends keyof ObjectType
  ? ObjectType[Path]
  : Path extends `${infer LeftSide}.${infer RightSide}`
    ? LeftSide extends keyof ObjectType
      ? ResolveType<NoUndefined<ObjectType[LeftSide]>, NoUndefined<RightSide>, OrElse>
      : Path extends `${infer LeftSideArrayProp}[${number}].${infer RightSideArrayProp}`
        ? LeftSideArrayProp extends keyof ObjectType
          ? ObjectType[LeftSideArrayProp] extends Array<infer U>
            ? ResolveType<NoUndefined<U>, NoUndefined<RightSideArrayProp>, OrElse>
            : OrElse
          : OrElse
        : OrElse
    : Path extends `${infer LeftSideArray}[${number}]`
      ? LeftSideArray extends keyof ObjectType
        ? ObjectType[NoUndefined<LeftSideArray>] extends Array<infer U>
          ? U
          : OrElse
        : OrElse
      : OrElse;

export function getOr<OrElse extends unknown, ObjecType extends object, Path extends string>(
  obj: ObjecType,
  path: Path,
  orElse: OrElse,
): ResolveType<ObjecType, Path, OrElse> | OrElse {
  return _get(obj, path, orElse) as ResolveType<ObjecType, Path, OrElse> | OrElse;
}
