'use client';

import NextImage from 'next/image';
import { ComponentProps, FC } from 'react';

import { getCarmaGenericImageLoader } from 'src/general/helpers/carmaGenericImageLoader';

type Props = Omit<ComponentProps<typeof NextImage>, 'loader'> & { src: string };

/**
 * Wrapper around NextJS Image component to allow dynamic selection of the image loader
 */
export const Image: FC<Props> = ({ ...props }) => {
  const loader = getCarmaGenericImageLoader(props.src);

  return <NextImage {...props} loader={loader} />;
};
