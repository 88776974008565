import { carmaBizClient } from 'src/services/instance/carmaBizClient';
import { ProductDetailsPayload } from 'src/types/ProductDetail.type';

export const getCarDetail = (sku: string) =>
  carmaBizClient
    .get<ProductDetailsPayload>(`/vehicles/${sku}`, {
      headers: { 'Content-Type': 'application/pdp+json' },
      // Prevent a bug from axios client-side ignoring `Content-Type` if data not set
      data: typeof window !== 'undefined' ? {} : undefined,
    })
    .then((res) => res.data)
    .catch(() => null);
