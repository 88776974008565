import { produce } from 'immer';

import { SavedSearch } from 'src/data/SavedSearchApi/SavedSearchApi';
import { FilterListPayload } from 'src/types/CarFilters.types';
import { filtersPayloadRowToPlpStoreFilters } from 'src/utils/plp/filtersPayloadRowToPlpStoreFilters';
import { getPlpUrlFromStoreFilters } from 'src/utils/plp/getPlpUrlFromStoreFilters';

import { storeFactory } from './storeFactory';

interface SavedSearchStoreStateData {
  savedSearches: SavedSearch[] | null;
  filterData: FilterListPayload | null;
  mappedSaveSearch: Map<string, SavedSearch>;
  savedSearchesLoaded: boolean;
  savedSearchesInitialised: boolean;
}

interface SavedSearchStoreStateFunctions {
  setSavedSearchesLoading: () => void;
  setSavedSearchesInitialised: (initialised: SavedSearchStoreStateData['savedSearchesInitialised']) => void;
  setSavedSearchesData: (savedSearches: SavedSearchStoreStateData['savedSearches']) => void;
  setFilterData: (filterData: SavedSearchStoreStateData['filterData']) => void;
  /** Empty the store. To be used when user logs out for example */
  clearSavedSearchesData: () => void;
}

const {
  store: savedSearchStore,
  StoreProvider: SavedSearchStoreProvider,
  useCreateStore: useCreateSavedSearchStore,
} = storeFactory<SavedSearchStoreStateData, SavedSearchStoreStateFunctions>(
  () => ({
    savedSearches: null,
    filterData: null,
    mappedSaveSearch: new Map(),
    savedSearchesLoaded: false,
    savedSearchesInitialised: false,
  }),
  (set, get) => ({
    setSavedSearchesLoading: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.savedSearchesLoaded = false;
        }),
      );
    },
    setSavedSearchesInitialised: (savedSearchesInitialised: SavedSearchStoreStateData['savedSearchesInitialised']) => {
      set((state) =>
        produce(state, (draft) => {
          draft.savedSearchesInitialised = savedSearchesInitialised;
        }),
      );
    },
    setSavedSearchesData: (savedSearches: SavedSearchStoreStateData['savedSearches']) => {
      set((state) =>
        produce(state, (draft) => {
          draft.savedSearches = savedSearches;
          draft.savedSearchesLoaded = true;
          draft.savedSearchesInitialised = true;
          draft.mappedSaveSearch = mapSavedSearches(get().filterData, savedSearches);
        }),
      );
    },
    setFilterData: (filterData: SavedSearchStoreStateData['filterData']) => {
      set((state) =>
        produce(state, (draft) => {
          draft.filterData = filterData;
          draft.mappedSaveSearch = mapSavedSearches(filterData, get().savedSearches);
        }),
      );
    },
    clearSavedSearchesData: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.savedSearches = null;
          draft.savedSearchesLoaded = false;
          draft.savedSearchesInitialised = false;
        }),
      );
    },
  }),
);

export { SavedSearchStoreProvider, savedSearchStore, useCreateSavedSearchStore };

const mapSavedSearches = (
  filterData: FilterListPayload | null,
  savedSearches: SavedSearch[] | null,
): SavedSearchStoreStateData['mappedSaveSearch'] => {
  let rawMap: [string, SavedSearch][] = [];
  if (filterData && savedSearches) {
    rawMap = savedSearches.map((item) => [
      getPlpUrlFromStoreFilters(filtersPayloadRowToPlpStoreFilters(filterData, item.filters)),
      item,
    ]);
  }

  const map = new Map<string, SavedSearch>(rawMap);
  return map;
};
