import { produce } from 'immer';
import { useLayoutEffect } from 'react';
import { create, StoreApi } from 'zustand';
import { createContext } from 'zustand-utils';

import { ContentfulCarmaError } from 'src/data/Contentful/ContentfulGetCarmaErrorListing';

/**
 *
 * Store to manage carma error
 * Follows Zustand store implementation with Next.JS example here:
 * https://github.com/vercel/next.js/tree/canary/examples/with-zustand
 *
 */

interface CarmaErrorStoreStateData {
  carmaError: Map<string, string>;
  getErrorMessage: (key: string, defaultValue: string, fallbackMessage?: string) => string;
}

export interface CarmaErrorStoreState extends CarmaErrorStoreStateData {
  set: (fn: (state: CarmaErrorStoreState) => void) => void;
}

let store: StoreApi<CarmaErrorStoreState> | undefined;
const carmaErrorStoreContext = createContext<StoreApi<CarmaErrorStoreState>>();

const mergeContentfulCarmaError = (carmaError: Map<string, string>, contentfulCarmaError?: ContentfulCarmaError[]) => {
  if (contentfulCarmaError) {
    contentfulCarmaError.forEach((item) => {
      carmaError.set(item.errorKey, item.errorMessage);
    });
  }
};

const getDefaultInitialState: (contentfulCarmaError?: ContentfulCarmaError[]) => CarmaErrorStoreStateData = (
  contentfulCarmaError?: ContentfulCarmaError[],
) => {
  const carmaError = new Map<string, string>();

  mergeContentfulCarmaError(carmaError, contentfulCarmaError);

  return {
    carmaError,
    getErrorMessage: (key: string, defaultMessage: string, fallbackMessage?: string) => {
      if (!key) return fallbackMessage ?? '';
      if (carmaError.has(key)) {
        // Handle the carmaError
        const carmaErrorData = carmaError.get(key)!;

        return carmaErrorData;
      }
      // Value not found, return value from api
      return defaultMessage;
    },
  };
};

const initialiseCarmaErrorStore = (contentfulCarmaError?: ContentfulCarmaError[]) =>
  create<CarmaErrorStoreState>((set) => ({
    ...getDefaultInitialState(contentfulCarmaError),
    set: (fn) => set(produce(fn)),
  }));

export const CarmaErrorStoreProvider = carmaErrorStoreContext.Provider;

export const useCreateCarmaErrorStore = (contentfulCarmaError?: ContentfulCarmaError[]) => {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initialiseCarmaErrorStore(contentfulCarmaError);
  }
  // End of server side code

  // Client side code:
  // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
  const isReusingStore = Boolean(store);
  store = store ?? initialiseCarmaErrorStore(contentfulCarmaError);
  // When next.js re-renders _app while re-using an older store, then replace current state with
  // the new state (in the next render cycle).
  // (Why next render cycle? Because react cannot re-render while a render is already in progress.
  // i.e. we cannot do a setState() as that will initiate a re-render)
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment (i.e. client or server)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
    // states on CSR page navigation or not. I have chosen not to, but if you choose to,
    // then add `serverInitialState = getDefaultInitialState()` here.
    if (isReusingStore) {
      store!.setState(
        {
          // re-use functions from existing store
          ...store!.getState(),
        },
        true, // replace states, rather than shallow merging
      );
    }
  });

  return () => store!;
};

export const useCarmaError = () => {
  const store = useCreateCarmaErrorStore();
  const { getErrorMessage } = store().getState();

  return getErrorMessage;
};
