import { WidgetConfig } from '@livechat/widget-react';
import { produce } from 'immer';

import { storeFactory } from './storeFactory';

interface LiveChatStoreStateData {
  visibility: WidgetConfig['visibility'];
}

interface LiveChatStoreStateFunctions {
  hideLiveChat: () => void;
  minimiseLiveChat: () => void;
  maximiseLiveChat: () => void;
  setLiveChatVisibility: (visibility: WidgetConfig['visibility']) => void;
}

const {
  store: liveChatStore,
  StoreProvider: LiveChatStoreProvider,
  useCreateStore: useCreateLiveChatStore,
} = storeFactory<LiveChatStoreStateData, LiveChatStoreStateFunctions>(
  () => ({
    visibility: undefined,
  }),
  (set) => ({
    setLiveChatVisibility: (visibility: WidgetConfig['visibility']) => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = visibility;
        }),
      );
    },
    hideLiveChat: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = 'hidden';
        }),
      );
    },
    minimiseLiveChat: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = 'minimized';
        }),
      );
    },
    maximiseLiveChat: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = 'maximized';
        }),
      );
    },
  }),
);

export { LiveChatStoreProvider, liveChatStore, useCreateLiveChatStore };
