import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { Icon } from 'src/general/Icons/Icon';

import classes from './CircularProgress.module.scss';

export const CircularProgress: FC<ComponentProps<typeof Icon>> = ({ className, ...props }) => (
  <div className={classNames(className, 'circularProgress', classes.root)} role="progressbar">
    <Icon {...props} className={classes.icon} viewBox="22 22 44 44">
      <circle className={classes.circle} cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
    </Icon>
  </div>
);
