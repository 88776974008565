export const enum BADGE {
  TWO_BREAK_PADS = '/images/components/BadgeIllustration/2-break-pads.svg',
  TWO_TYRES = '/images/components/BadgeIllustration/2-tyres.svg',
  FOUR_BREAK_PADS = '/images/components/BadgeIllustration/4-break-pads.svg',
  FOUR_TYRES = '/images/components/BadgeIllustration/4-tyres.svg',
  LOW_KMS = '/images/components/BadgeIllustration/low-kms.svg',
  LOW_KMS_PER_YEAR = '/images/components/BadgeIllustration/low-kms-per-year.svg',
  REGO = '/images/components/BadgeIllustration/rego.svg',
  SINGLE_OWNER = '/images/components/BadgeIllustration/single-owner.svg',
  SPARE_TYRE = '/images/components/BadgeIllustration/spare-tyre.svg',
  SERVICE = '/images/components/BadgeIllustration/service.svg',
  NEW_WIPERS = '/images/components/BadgeIllustration/new-wipers.svg',
  WARRANTY = '/images/components/BadgeIllustration/warranty.svg',
  QUALITY = '/images/components/BadgeIllustration/quality.svg',
  HONEST_VALUATION = '/images/components/BadgeIllustration/honest-valuation.svg',
  CARMA_TOUCH = '/images/components/BadgeIllustration/carma-touch.svg',
  CALENDAR = '/images/components/BadgeIllustration/calendar.svg',
  THIRD_PARTY_2 = '/images/components/BadgeIllustration/third-party-2.svg',
  PAY = '/images/components/BadgeIllustration/pay.svg',
  LICENSE = '/images/components/BadgeIllustration/licence.svg',
  AUSSIE = '/images/components/BadgeIllustration/aussie.svg',
  QUOTES = '/images/components/BadgeIllustration/quotes.svg',
  FULLY_CHARGED = '/images/components/BadgeIllustration/fully-charged.svg',
  CREDIT_CHECK = '/images/components/BadgeIllustration/credit-check.svg',
  FLIP_THE_MAP = '/images/components/BadgeIllustration/flip-the-map.svg',
  KEYS = '/images/components/BadgeIllustration/keys.svg',
  FINANCE = '/images/components/BadgeIllustration/finance.svg',
  REVIEWS = '/images/components/BadgeIllustration/reviews.svg',
  FINANCE_ONLINE = '/images/components/BadgeIllustration/finance-online.svg',
  HONESTY = '/images/components/BadgeIllustration/honesty.svg',
  THIRD_PARTY = '/images/components/BadgeIllustration/third-party.svg',
  SMOOTH_ACCEL = '/images/components/BadgeIllustration/smooth-accel.svg',
  GOT_YOUR_BACK = '/images/components/BadgeIllustration/got-your-back.svg',
  TRADE_IN = '/images/components/BadgeIllustration/trade-in.svg',
  SEVEN_DAY_RETURN = '/images/components/BadgeIllustration/7-day-return-guarantee.svg',
  SHOTGUN = '/images/components/BadgeIllustration/shotgun.svg',
  COMPLETE = '/images/components/BadgeIllustration/complete.svg',
  WAREHOUSE = '/images/components/BadgeIllustration/warehouse.svg',
  DOCUMENTS = '/images/components/BadgeIllustration/documents.svg',
  SUCCESS = '/images/components/BadgeIllustration/success.svg',
  SUCCESS_1 = '/images/components/BadgeIllustration/success-1.svg',
  ACCOUNT_EMPTY = '/images/components/BadgeIllustration/account-empty.svg',
  TRADE_IN_CREATION = '/images/components/BadgeIllustration/trade-in-creation.svg',
  SELL_CREATION = '/images/components/BadgeIllustration/sell-creation.svg',
  INTEREST_IN_FINANCING = '/images/components/BadgeIllustration/interest-in-financing.svg',
  DELIVERY = '/images/components/BadgeIllustration/delivery.svg',
  POLAROID_AND_KEYS = '/images/components/BadgeIllustration/polaroid-and-keys.svg',
  POLAROID = '/images/components/BadgeIllustration/polaroid.svg',
  SAVE_TIME = '/images/components/BadgeIllustration/save-time.svg',
  FAVORITE = '/images/components/BadgeIllustration/favorite.svg',
  ROADSIDE_ASSISTANCE = '/images/components/BadgeIllustration/roadside-assistance.svg',
  EXTENDED_COVERAGE_WARRANTY = '/images/components/BadgeIllustration/extended-coverage-warranty.svg',
  CAR_MATCH = '/images/components/BadgeIllustration/car-match.svg',
}
