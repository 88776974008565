'use client';

import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';

import './Shade.global.scss';
import styles from './Shade.module.scss';

/**
 * Component in charge of displaying the highlight shade for example on buttons
 */
export const Shade: FC = () => {
  const shade = useRef<HTMLSpanElement>(null);
  const [mousePosition, setMousePosition] = useState<[number, number]>([0.5, 0.5]);

  useEffect(() => {
    let mounted = true;
    const shadeParent = shade.current?.parentElement;

    const handleMouseMove = (e: MouseEvent) => {
      const button = e.target as HTMLElement;
      const percentageX = (e.offsetX / button.offsetWidth) * 100;
      const percentageY = (e.offsetY / button.offsetHeight) * 100;
      if (mounted) {
        setMousePosition([percentageX, percentageY]);
      }
    };

    if (shadeParent) {
      shadeParent.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      mounted = false;
      if (shadeParent) {
        shadeParent.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  return (
    <span
      className={classNames(styles.root, 'shade')}
      ref={shade}
      style={{ '--shade-mouse-x': mousePosition[0], '--shade-mouse-y': mousePosition[1] } as any}
    ></span>
  );
};
