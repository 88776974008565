import { ApolloClient, InMemoryCache } from '@apollo/client';

const cmsGraphClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CMS_SPACE_ID}/environments/${process.env.REACT_APP_CMS_ENVIRONMENT}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CMS_DELIVERY_TOKEN}`,
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
  },
});

export const cmsGraphClientPreview = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CMS_SPACE_ID}/environments/${process.env.REACT_APP_CMS_ENVIRONMENT}`,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CMS_PREVIEW_TOKEN}`,
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
  },
});

export default cmsGraphClient;
