import { FC, ReactNode } from 'react';
import { Components } from 'react-markdown';

interface Props {
  children: ReactNode;
  author?: string;
}

export const Quote: FC<Props> = ({ children, author }) => {
  return (
    <figure className="quote">
      <blockquote className="quote-content">
        {typeof children === 'string' || isChildrenStringOnly(children) ? <p>{children}</p> : children}
      </blockquote>
      {!!author && <figcaption className="quote-author">{author}</figcaption>}
    </figure>
  );
};

export const Blockquote: Components['blockquote'] = ({ children }) => <Quote>{children}</Quote>;

const isChildrenStringOnly = (children: ReactNode): boolean =>
  Array.isArray(children) && children.length === 1 && typeof children[0] === 'string';
