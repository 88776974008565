import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie';

import { getSha256Hash } from './getSha256Hash';

const LD_USER_GROUP = 'ldUserGroup';
const cookies = new Cookies();

const NUM_BUCKETS = 10;

const EXPIRY_DURATION = 90 * 24 * 60 * 60 * 100;

const genRandomUserGroup = () => {
  const randomNumber = Math.floor(Math.random() * NUM_BUCKETS) + 1;
  return `user_group-${randomNumber}`;
};

export const getInitialLdUserGroup = () => {
  return getAnonymousUserGroup();
};

export const getLdUserGroup = async (userId?: string) => {
  const userGroup = userId ? `user_group-${await hashUUIDToBucket(userId)}` : getAnonymousUserGroup();
  const now = new Date().getTime();
  const expires = new Date(now + EXPIRY_DURATION);
  cookies.set(LD_USER_GROUP, userGroup, { path: '/', secure: process.env.NODE_ENV === 'production', expires });
  return userGroup;
};

const getAnonymousUserGroup = () => {
  const existingUserGroup = cookies.get(LD_USER_GROUP);
  if (!isEmpty(existingUserGroup)) {
    return existingUserGroup;
  }
  return genRandomUserGroup();
};

export const hashUUIDToBucket = async (uuid: string): Promise<number> => {
  const hash = await getSha256Hash(uuid);

  // Convert hash to a 32-bit integer
  const int32Hash = parseInt(hash, 16);

  return Math.abs(int32Hash % NUM_BUCKETS) + 1;
};
