/**
 * Microcopy keys
 *
 * Convention regarding microcopy key naming:
 * - Always use snakecase in uppercase: `MY_MICROCOPY_NAME`
 * - Name should start from the most generic attribute to the least.
 *   An example of this naming structure can be: `{PILLAR}_{COMPONENT}_{ATOM}`
 *   or `{PILLAR}_{PAGE}_{COMPONENT}_{ATOM}`
 * - In case your microcopy supports markdown or dynamic tokens,
 *   please add some documentation associated with your key
 */
export const enum MICROCOPY {
  //
  //
  // General microcopy (used across the different pillars of the website)
  //
  //
  GENERAL_FOOTER_SUPPORT_TITLE = 'GENERAL_FOOTER_SUPPORT_TITLE',
  GENERAL_FOOTER_SUPPORT_CONTENT = 'GENERAL_FOOTER_SUPPORT_CONTENT',
  GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_LABEL = 'GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_LABEL',
  GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_VALUE = 'GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_VALUE',
  GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_LABEL = 'GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_LABEL',
  GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_VALUE = 'GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_VALUE',
  GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_LABEL = 'GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_LABEL',
  GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_VALUE = 'GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_VALUE',
  GENERAL_FOOTER_COPYRIGHT = 'GENERAL_FOOTER_COPYRIGHT',
  GENERAL_FOOTER_FORM_LEGALS = 'GENERAL_FOOTER_FORM_LEGALS',
  GENERAL_MODAL_HELP_TITLE = 'GENERAL_MODAL_HELP_TITLE',
  GENERAL_MODAL_HELP_CONTENT = 'GENERAL_MODAL_HELP_CONTENT',
  GENERAL_COPY_TO_CLIPBOARD_COPIED = 'GENERAL_COPY_TO_CLIPBOARD_COPIED',
  GENERAL_CTA_START_CHECKOUT = 'GENERAL_CTA_START_CHECKOUT',
  GENERAL_CTA_NOT_AVAILABLE = 'GENERAL_CTA_NOT_AVAILABLE',
  GENERAL_CTA_MESSAGE = 'GENERAL_CTA_MESSAGE',
  GENERAL_PRIVACY_COLLECTION_NOTICE = 'GENERAL_PRIVACY_COLLECTION_NOTICE',
  GENERAL_EXCLUDE_GOVERNMENT_CHARGES = 'GENERAL_EXCLUDE_GOVERNMENT_CHARGES',
  GENERAL_GOVERNMENT_CHARGES_MODAL_TITLE = 'GENERAL_GOVERNMENT_CHARGES_MODAL_TITLE',
  GENERAL_GOVERNMENT_CHARGES_MODAL_NOTE = 'GENERAL_GOVERNMENT_CHARGES_MODAL_NOTE',
  GENERAL_ERROR_500_TITLE = 'GENERAL_ERROR_500_TITLE',
  GENERAL_ERROR_500_BUTTON_LABEL = 'GENERAL_ERROR_500_BUTTON_LABEL',
  GENERAL_ERROR_400_TITLE = 'GENERAL_ERROR_400_TITLE',
  GENERAL_ERROR_400_BUTTON_LABEL = 'GENERAL_ERROR_400_BUTTON_LABEL',
  GENERAL_ERROR_PROCESS_PAYMENT_BUTTON_LABEL = 'GENERAL_ERROR_PROCESS_PAYMENT_BUTTON_LABEL',
  GENERAL_ERROR_PROCESS_PAYMENT_TITLE = 'GENERAL_ERROR_PROCESS_PAYMENT_TITLE',
  GENERAL_ERROR_PROCESS_PAYMENT_MESSAGE = 'GENERAL_ERROR_PROCESS_PAYMENT_MESSAGE',
  //
  //
  // Promotion microcopy
  //
  //
  PROMO_1 = 'PROMO_1',
  PROMO_1_TAB_LABEL = 'PROMO_1_TAB_LABEL',
  PROMO_1_CTA_LABEL = 'PROMO_1_CTA_LABEL',
  PROMO_1_URL_PARAM = 'PROMO_1_URL_PARAM',
  PROMO_1_TC = 'PROMO_1_TC',
  PROMO_1_INFO_DESCRIPTION = 'PROMO_1_INFO_DESCRIPTION',
  PROMO_1_INFO_LINK = 'PROMO_1_INFO_LINK',
  PROMO_1_INFO_CTA = 'PROMO_1_INFO_CTA',
  PROMO_2 = 'PROMO_2',
  PROMO_2_TAB_LABEL = 'PROMO_2_TAB_LABEL',
  PROMO_2_CTA_LABEL = 'PROMO_2_CTA_LABEL',
  PROMO_2_URL_PARAM = 'PROMO_2_URL_PARAM',
  PROMO_2_TC = 'PROMO_2_TC',
  PROMO_2_INFO_DESCRIPTION = 'PROMO_2_INFO_DESCRIPTION',
  PROMO_2_INFO_LINK = 'PROMO_2_INFO_LINK',
  PROMO_2_INFO_CTA = 'PROMO_2_INFO_CTA',
  PROMO_3 = 'PROMO_3',
  PROMO_3_TAB_LABEL = 'PROMO_3_TAB_LABEL',
  PROMO_3_CTA_LABEL = 'PROMO_3_CTA_LABEL',
  PROMO_3_URL_PARAM = 'PROMO_3_URL_PARAM',
  PROMO_3_TC = 'PROMO_3_TC',
  PROMO_3_INFO_DESCRIPTION = 'PROMO_3_INFO_DESCRIPTION',
  PROMO_3_INFO_LINK = 'PROMO_3_INFO_LINK',
  PROMO_3_INFO_CTA = 'PROMO_3_INFO_CTA',
  PROMO_4 = 'PROMO_4',
  PROMO_4_BANNER = 'PROMO_4_BANNER',
  //
  //
  // Hygiene microcopy
  //
  //
  HYGIENE_BENEFIT_COLUMN_1_CONTENT = 'HYGIENE_BENEFIT_COLUMN_1_CONTENT',
  HYGIENE_BENEFIT_COLUMN_1_TITLE = 'HYGIENE_BENEFIT_COLUMN_1_TITLE',
  HYGIENE_BENEFIT_COLUMN_2_CONTENT = 'HYGIENE_BENEFIT_COLUMN_2_CONTENT',
  HYGIENE_BENEFIT_COLUMN_2_TITLE = 'HYGIENE_BENEFIT_COLUMN_2_TITLE',
  HYGIENE_BENEFIT_COLUMN_3_CONTENT = 'HYGIENE_BENEFIT_COLUMN_3_CONTENT',
  HYGIENE_BENEFIT_COLUMN_3_TITLE = 'HYGIENE_BENEFIT_COLUMN_3_TITLE',
  HYGIENE_BENEFIT_HEADER = 'HYGIENE_BENEFIT_HEADER',
  HYGIENE_CAR_FINANCE = 'HYGIENE_CAR_FINANCE',
  HYGIENE_CAR_FINANCE_APPLICATION = 'HYGIENE_CAR_FINANCE_APPLICATION',
  HYGIENE_CAR_FINANCE_CTA = 'HYGIENE_CAR_FINANCE_CTA',
  HYGIENE_CAR_FINANCE_QUOTE = 'HYGIENE_CAR_FINANCE_QUOTE',
  HYGIENE_CAR_FINANCE_RATES = 'HYGIENE_CAR_FINANCE_RATES',
  HYGIENE_CAROUSEL_BODY_TYPE_0_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_0_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_0_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_0_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_1_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_1_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_1_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_1_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_2_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_2_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_2_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_2_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_3_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_3_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_3_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_3_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_4_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_4_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_4_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_4_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_5_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_5_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_5_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_5_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_6_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_6_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_6_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_6_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_7_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_7_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_7_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_7_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_8_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_8_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_8_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_8_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_TITLE = 'HYGIENE_CAROUSEL_BODY_TYPE_TITLE',
  HYGIENE_CAROUSEL_BUDGET_0_CTA = 'HYGIENE_CAROUSEL_BUDGET_0_CTA',
  HYGIENE_CAROUSEL_BUDGET_0_LABEL = 'HYGIENE_CAROUSEL_BUDGET_0_LABEL',
  HYGIENE_CAROUSEL_BUDGET_1_CTA = 'HYGIENE_CAROUSEL_BUDGET_1_CTA',
  HYGIENE_CAROUSEL_BUDGET_1_LABEL = 'HYGIENE_CAROUSEL_BUDGET_1_LABEL',
  HYGIENE_CAROUSEL_BUDGET_2_CTA = 'HYGIENE_CAROUSEL_BUDGET_2_CTA',
  HYGIENE_CAROUSEL_BUDGET_2_LABEL = 'HYGIENE_CAROUSEL_BUDGET_2_LABEL',
  HYGIENE_CAROUSEL_BUDGET_3_CTA = 'HYGIENE_CAROUSEL_BUDGET_3_CTA',
  HYGIENE_CAROUSEL_BUDGET_3_LABEL = 'HYGIENE_CAROUSEL_BUDGET_3_LABEL',
  HYGIENE_CAROUSEL_BUDGET_4_CTA = 'HYGIENE_CAROUSEL_BUDGET_4_CTA',
  HYGIENE_CAROUSEL_BUDGET_4_LABEL = 'HYGIENE_CAROUSEL_BUDGET_4_LABEL',
  HYGIENE_CAROUSEL_BUDGET_TITLE = 'HYGIENE_CAROUSEL_BUDGET_TITLE',
  HYGIENE_CAROUSEL_PROMOTION_TITLE = 'HYGIENE_CAROUSEL_PROMOTION_TITLE',
  HYGIENE_CONTACT_US_GET_IN_TOUCH_CONTENT = 'HYGIENE_CONTACT_US_GET_IN_TOUCH_CONTENT',
  HYGIENE_CONTACT_US_GET_IN_TOUCH_HOURS = 'HYGIENE_CONTACT_US_GET_IN_TOUCH_HOURS',
  HYGIENE_COVID_MESSAGE = 'HYGIENE_COVID_MESSAGE',
  HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_CONTENT = 'HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_CONTENT',
  HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_TITLE = 'HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_TITLE',
  HYGIENE_EMAIL_DEFAULT_UNSUBSCRIBE_TITLE = 'HYGIENE_EMAIL_DEFAULT_UNSUBSCRIBE_TITLE',
  HYGIENE_EMAIL_UNSUBSCRIBE_BUTTON_LABEL = 'HYGIENE_EMAIL_UNSUBSCRIBE_BUTTON_LABEL',
  HYGIENE_EMAIL_UNSUBSCRIBE_TITLE = 'HYGIENE_EMAIL_UNSUBSCRIBE_TITLE',
  HYGIENE_EMAIL_UNSUBSCRIBE_WATCHLIST_VEHICLE_AVAILABLE_TITLE = 'HYGIENE_EMAIL_UNSUBSCRIBE_WATCHLIST_VEHICLE_AVAILABLE_TITLE',
  HYGIENE_FINANCE_CALCULATOR_CTA = 'HYGIENE_FINANCE_CALCULATOR_CTA',
  HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_1 = 'HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_1',
  HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_2 = 'HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_2',
  HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_3 = 'HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_3',
  HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE = 'HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE',
  HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_BORROW_AMOUNT = 'HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_BORROW_AMOUNT',
  HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_REPAYMENT = 'HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_REPAYMENT',
  HYGIENE_FINANCE_CALCULATOR_TITLE = 'HYGIENE_FINANCE_CALCULATOR',
  HYGIENE_FINANCE_CALCULATOR_WEEKLY_FEE = 'HYGIENE_FINANCE_CALCULATOR_WEEKLY_FEE',
  HYGIENE_FINANCE_PROVIDERS_COLUMN_1_DESCRIPTION = 'HYGIENE_FINANCE_PROVIDERS_COLUMN_1_DESCRIPTION',
  HYGIENE_FINANCE_PROVIDERS_COLUMN_2_DESCRIPTION = 'HYGIENE_FINANCE_PROVIDERS_COLUMN_2_DESCRIPTION',
  HYGIENE_FINANCE_PROVIDERS_COLUMN_3_DESCRIPTION = 'HYGIENE_FINANCE_PROVIDERS_COLUMN_3_DESCRIPTION',
  HYGIENE_FINANCE_PROVIDERS_HEADER = 'HYGIENE_FINANCE_PROVIDERS_HEADER',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_HEADER = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_HEADER',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_1 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_1',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_2 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_2',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_3 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_3',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_4 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_4',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_5 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_5',
  HYGIENE_FLEXIBLE_PAYMENT_ADS = 'HYGIENE_FLEXIBLE_PAYMENT_ADS',
  HYGIENE_FLEXIBLE_PAYMENT_COLUMN_1 = 'HYGIENE_FLEXIBLE_PAYMENT_COLUMN_1',
  HYGIENE_FLEXIBLE_PAYMENT_COLUMN_2 = 'HYGIENE_FLEXIBLE_PAYMENT_COLUMN_2',
  HYGIENE_FLEXIBLE_PAYMENT_COLUMN_3 = 'HYGIENE_FLEXIBLE_PAYMENT_COLUMN_3',
  HYGIENE_FLEXIBLE_PAYMENT_CTA = 'HYGIENE_FLEXIBLE_PAYMENT_CTA',
  HYGIENE_FLEXIBLE_PAYMENT_HEADER = 'HYGIENE_FLEXIBLE_PAYMENT_HEADER',
  HYGIENE_FLEXIBLE_PAYMENT_REMINDER = 'HYGIENE_FLEXIBLE_PAYMENT_REMINDER',
  HYGIENE_FLEXIBLE_TABLE_SECTION_NONE_LABEL = 'HYGIENE_FLEXIBLE_TABLE_SECTION_NONE_LABEL',
  HYGIENE_HOMEPAGE_BROWSE_ALL_CARS = 'HYGIENE_HOMEPAGE_BROWSE_ALL_CARS',
  HYGIENE_HOMEPAGE_HEADER_NEW_SEARCH_PLACEHOLDER = 'HYGIENE_HOMEPAGE_HEADER_NEW_SEARCH_PLACEHOLDER',
  HYGIENE_HOMEPAGE_HERO_CARMATCH = 'HYGIENE_HOMEPAGE_HERO_CARMATCH',
  HYGIENE_HOMEPAGE_HERO_HEADING = 'HYGIENE_HOMEPAGE_HERO_HEADING',
  HYGIENE_HOMEPAGE_HERO_SUBHEADING = 'HYGIENE_HOMEPAGE_HERO_SUBHEADING',
  HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_DESCRIPTION = 'HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_DESCRIPTION',
  HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_HEADER = 'HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_HEADER',
  HYGIENE_HOMEPAGE_NEW_HERO_HEADING = 'HYGIENE_HOMEPAGE_NEW_HERO_HEADING',
  HYGIENE_HOMEPAGE_NEW_USP_CHECKLIST = 'HYGIENE_HOMEPAGE_NEW_USP_CHECKLIST',
  HYGIENE_HOMEPAGE_NEW_USP_RETURN = 'HYGIENE_HOMEPAGE_NEW_USP_RETURN',
  HYGIENE_HOMEPAGE_SEARCH_MAKE = 'HYGIENE_HOMEPAGE_SEARCH_MAKE',
  HYGIENE_HOMEPAGE_SEARCH_MODEL = 'HYGIENE_HOMEPAGE_SEARCH_MODEL',
  HYGIENE_HOMEPAGE_USP_CHECKLIST = 'HYGIENE_HOMEPAGE_USP_CHECKLIST',
  HYGIENE_HOMEPAGE_USP_RETURN = 'HYGIENE_HOMEPAGE_USP_RETURN',
  HYGIENE_HOMEPAGE_USP_WARRANTY = 'HYGIENE_HOMEPAGE_USP_WARRANTY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_1_SUMMARY = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_1_SUMMARY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_1_TITLE = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_1_TITLE',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_2_SUMMARY = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_2_SUMMARY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_2_TITLE = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_2_TITLE',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_3_SUMMARY = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_3_SUMMARY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_3_TITLE = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_3_TITLE',
  HYGIENE_HOW_CARMA_WORKS_CTA = 'HYGIENE_HOW_CARMA_WORKS_CTA',
  HYGIENE_HOW_CARMA_WORKS_HEADER = 'HYGIENE_HOW_CARMA_WORKS_HEADER',
  HYGIENE_HOW_FINANCE_WORKS_HERO = 'HYGIENE_HOW_FINANCE_WORKS_HERO',
  HYGIENE_JOIN_THE_1000S = 'HYGIENE_JOIN_THE_1000S',
  HYGIENE_NEW_CARS_EVERY_DAY = 'HYGIENE_NEW_CARS_EVERY_DAY',
  HYGIENE_NRMA = 'HYGIENE_NRMA',
  HYGIENE_OUR_COMMITMENT_COLUMN_1 = 'HYGIENE_OUR_COMMITMENT_COLUMN_1',
  HYGIENE_OUR_COMMITMENT_COLUMN_2 = 'HYGIENE_OUR_COMMITMENT_COLUMN_2',
  HYGIENE_OUR_COMMITMENT_COLUMN_3 = 'HYGIENE_OUR_COMMITMENT_COLUMN_3',
  HYGIENE_OUR_COMMITMENT_COLUMN_4 = 'HYGIENE_OUR_COMMITMENT_COLUMN_4',
  HYGIENE_OUR_COMMITMENT_HEADER = 'HYGIENE_OUR_COMMITMENT_HEADER',
  HYGIENE_PROMOTION = 'HYGIENE_PROMOTION',
  HYGIENE_PROMOTION_CTA = 'HYGIENE_PROMOTION_CTA',
  HYGIENE_PROMOTION_LINK = 'HYGIENE_PROMOTION_LINK',
  HYGIENE_SOCIAL_PROOF_DESCRIPTION_1 = 'HYGIENE_SOCIAL_PROOF_DESCRIPTION_1',
  HYGIENE_SOCIAL_PROOF_DESCRIPTION_2 = 'HYGIENE_SOCIAL_PROOF_DESCRIPTION_2',
  HYGIENE_SOCIAL_PROOF_HEADER = 'HYGIENE_SOCIAL_PROOF_HEADER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_COLOUR = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_COLOUR',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_HELPER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_HELPER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_1K_TO_5K = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_1K_TO_5K',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_OVER_5K = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_OVER_5K',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNDER_1K = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNDER_1K',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNKNOWN = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNKNOWN',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_FIRST_OWNER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_FIRST_OWNER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_MAJOR_ACCIDENT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_MAJOR_ACCIDENT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_1 = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_1',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_2_PLUS = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_2_PLUS',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_FULL = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_FULL',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_NONE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_NONE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL_GENUINE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL_GENUINE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_BUTTON_LABEL = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_BUTTON_LABEL',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_BACK = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_BACK',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_FRONT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_FRONT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_LEFT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_LEFT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_RIGHT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_RIGHT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SAVE_UPLOAD_BUTTON = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SAVE_UPLOAD_BUTTON',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_BUTTON = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_BUTTON',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_HEADING = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_HEADING',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_TEXT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_TEXT',
  HYGIENE_TRADE_IN_ENQUIRY_DEFAULT_NEXT = 'HYGIENE_TRADE_IN_ENQUIRY_DEFAULT_NEXT',
  HYGIENE_TRADE_IN_ENQUIRY_DROP_IMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_DROP_IMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_CONVERTIBLE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_CONVERTIBLE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_COUPE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_COUPE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_HATCH = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_HATCH',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_PEOPLE_MOVER = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_PEOPLE_MOVER',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SEDAN = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SEDAN',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SUV = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SUV',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_UTE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_UTE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_VAN = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_VAN',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_WAGON = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_WAGON',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_DIESEL = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_DIESEL',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_ELECTRIC = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_ELECTRIC',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_HYBRID = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_HYBRID',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_LPG = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_LPG',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PETROL = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PETROL',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PLUG_IN_HYBRID = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PLUG_IN_HYBRID',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MAKE_NAME = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MAKE_NAME',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_NAME = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_NAME',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_YEAR = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_YEAR',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_REGO = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_REGO',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_AUTOMATIC = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_AUTOMATIC',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_MANUAL = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_MANUAL',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_VARIANT = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_VARIANT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_HAIL = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_HAIL',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_SCRATCH_DENT = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_SCRATCH_DENT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_HAIL = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_HAIL',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_SCRATCH_DENT = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_SCRATCH_DENT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_NULL = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_NULL',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_PAINT = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_PAINT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_RUST = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_RUST',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_WINDSHIELD = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_WINDSHIELD',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_LARGE = 'HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_LARGE',
  HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_SMALL = 'HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_SMALL',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_CAR_LOCATION = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_CAR_LOCATION',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_DASHBOARD_PANELS = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_DASHBOARD_PANELS',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_NULL = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_NULL',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_RIPPED_SEATS = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_RIPPED_SEATS',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_STAINS = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_STAINS',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_INVALID_FILE_TYPE = 'HYGIENE_TRADE_IN_ENQUIRY_INVALID_FILE_TYPE',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_AIRBAG = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_AIRBAG',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_CHECK_ENGINE = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_CHECK_ENGINE',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_NULL = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_NULL',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_OTHER = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_OTHER',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRACTION_ABS = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRACTION_ABS',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRANSMISSION = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRANSMISSION',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_OPTIONAL_EXTRAS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_OPTIONAL_EXTRAS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_1 = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_1',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_2 = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_2',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_3 = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_3',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_NAME = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_NAME',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_REMOVE_IMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_REMOVE_IMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_SELL = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_SELL',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_TRADE_IN = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_TRADE_IN',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_NEW = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_NEW',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_USED = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_USED',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_NO_LONGER_NEED = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_NO_LONGER_NEED',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_ALREADY_FOUND = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_ALREADY_FOUND',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_NOT_STARTED = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_NOT_STARTED',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_STILL_LOOKING = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_STILL_LOOKING',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_ASAP = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_ASAP',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_CAR_FOUND = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_CAR_FOUND',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_FEW_MONTHS = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_FEW_MONTHS',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NOT_SELLING_YET = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NOT_SELLING_YET',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION3_6_WEEKS = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION3_6_WEEKS',
  HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE_PDP_VARIANT = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE_PDP_VARIANT',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_HEADER = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_HEADER',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_MEANWHILE = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_MEANWHILE',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_NEXT = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_NEXT',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_OPTIONAL_PDP = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_OPTIONAL_PDP',
  HYGIENE_TRADE_IN_ENQUIRY_TOO_MANY_FILES = 'HYGIENE_TRADE_IN_ENQUIRY_TOO_MANY_FILES',
  HYGIENE_TRADE_IN_ENQUIRY_UPLOAD_IMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_UPLOAD_IMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_EMAIL = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_EMAIL',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_FIRSTNAME = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_FIRSTNAME',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_LASTNAME = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_LASTNAME',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_PHONE = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_PHONE',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_TITLE',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_1 = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_1',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_2 = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_2',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_3 = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_3',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_TITLE = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_TITLE',
  HYGIENE_TRADE_IN_TAG_COMPLETE = 'HYGIENE_TRADE_IN_TAG_COMPLETE',
  HYGIENE_TRADE_IN_TAG_OPTIONAL = 'HYGIENE_TRADE_IN_TAG_OPTIONAL',
  HYGIENE_TRADE_IN_TAG_REQUIRED = 'HYGIENE_TRADE_IN_TAG_REQUIRED',
  HYGIENE_TRADE_IN_USPS_CONTENT_1 = 'HYGIENE_TRADE_IN_USPS_CONTENT_1',
  HYGIENE_TRADE_IN_USPS_CONTENT_2 = 'HYGIENE_TRADE_IN_USPS_CONTENT_2',
  HYGIENE_TRADE_IN_USPS_CONTENT_3 = 'HYGIENE_TRADE_IN_USPS_CONTENT_3',
  HYGIENE_TRADE_IN_USPS_TITLE_1 = 'HYGIENE_TRADE_IN_USPS_TITLE_1',
  HYGIENE_TRADE_IN_USPS_TITLE_2 = 'HYGIENE_TRADE_IN_USPS_TITLE_2',
  HYGIENE_TRADE_IN_USPS_TITLE_3 = 'HYGIENE_TRADE_IN_USPS_TITLE_3',
  HYGIENE_WHAT_HAPPENS_NEXT_DESCRIPTION = 'HYGIENE_WHAT_HAPPENS_NEXT_DESCRIPTION',
  HYGIENE_WHAT_HAPPENS_NEXT_TITLE = 'HYGIENE_WHAT_HAPPENS_NEXT_TITLE',
  HYGIENE_WHY_CARMA_CTA = 'HYGIENE_WHY_CARMA_CTA',
  //
  //
  // Page Builder/Flexible Content microcopy
  //
  //
  CUSTOMER_REVIEWS_SUMMARY_CTA_LABEL = 'CUSTOMER_REVIEWS_SUMMARY_CTA_LABEL',
  CUSTOMER_REVIEWS_SUMMARY_GOOGLE_COUNT = 'CUSTOMER_REVIEWS_SUMMARY_GOOGLE_COUNT',
  CUSTOMER_REVIEWS_SUMMARY_GOOGLE_AVERAGE = 'CUSTOMER_REVIEWS_SUMMARY_GOOGLE_AVERAGE',
  CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_COUNT = 'CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_COUNT',
  CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_AVERAGE = 'CUSTOMER_REVIEWS_SUMMARY_PRODUCT_REVIEW_AVERAGE',
  //
  //
  // Product listing microcopy (PLP)
  //
  //
  PLP_NO_RESULTS_FORM = 'PLP_NO_RESULTS_FORM',
  PLP_SEARCH_DEFAULT_TITLE = 'PLP_DEFAULT_TITLE',
  PLP_SEARCH_DEFAULT_SEO_COPY = 'PLP_SEARCH_DEFAULT_SEO_COPY',
  PLP_SEARCH_MULTI_TITLE = 'PLP_SEARCH_MULTI_TITLE',
  PLP_SEARCH_MULTI_SEO_COPY = 'PLP_SEARCH_MULTI_SEO_COPY',
  PLP_SEARCH_NO_RESULT_TITLE = 'PLP_SEARCH_NO_RESULT_TITLE',
  PLP_SEARCH_NO_RESULT_SEO_COPY_TITLE = 'PLP_SEARCH_NO_RESULT_SEO_COPY_TITLE',
  PLP_SEARCH_NO_RESULT_SEO_COPY_DESCRIPTION = 'PLP_SEARCH_NO_RESULT_SEO_COPY_DESCRIPTION',
  PLP_MARKETING_BANNER_TITLE = 'PLP_MARKETING_BANNER_TITLE',
  PLP_MARKETING_BANNER_DESCRIPTION = 'PLP_MARKETING_BANNER_DESCRIPTION',
  PLP_MARKETING_BANNER_CTA = 'PLP_MARKETING_BANNER_CTA',

  //
  //
  // Product detail microcopy (PDP)
  //
  //
  PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_TITLE = 'PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_TITLE',
  PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_TITLE = 'PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_TITLE',
  PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_CONTENT = 'PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_CONTENT',
  PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_CONTENT = 'PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_CONTENT',
  PDP_PURCHASE_INPROGRESS_MODAL_CONFIRM_BUTTON_LABEL = 'PDP_PURCHASE_INPROGRESS_MODAL_CONFIRM_BUTTON_LABEL',
  PDP_PURCHASE_INPROGRESS_MODAL_ADD_TO_WATCHLIST = 'PDP_PURCHASE_INPROGRESS_MODAL_ADD_TO_WATCHLIST',
  PDP_PURCHASE_INPROGRESS_MODAL_ADDED_TO_WATCHLIST_LABEL = 'PDP_PURCHASE_INPROGRESS_MODAL_ADDED_TO_WATCHLIST_LABEL',
  PDP_SET_LOCATION_FOR_DELIVERY_ADD_LABEL = 'PDP_SET_LOCATION_FOR_DELIVERY_ADD_LABEL',
  PDP_SET_LOCATION_FOR_DELIVERY_EDIT_LABEL = 'PDP_SET_LOCATION_FOR_DELIVERY_EDIT_LABEL',
  PDP_DELIVERY_LOCATION_POPUP_TITLE = 'PDP_DELIVERY_LOCATION_POPUP_TITLE',
  PDP_DELIVERY_LOCATION_POPUP_DESCRIPTION = 'PDP_DELIVERY_LOCATION_POPUP_DESCRIPTION',
  PDP_DELIVERY_LOCATION_POPUP_BUTTON_LABEL = 'PDP_DELIVERY_LOCATION_POPUP_BUTTON_LABEL',
  PDP_DELIVERY_LOCATION_POPUP_SEARCH_LABEL = 'PDP_DELIVERY_LOCATION_POPUP_SEARCH_LABEL',
  PDP_DELIVERY_LOCATION_POPUP_CANCEL_LABEL = 'PDP_DELIVERY_LOCATION_POPUP_CANCEL_LABEL',
  PDP_PRICE_CARD_CONTINUE_CHECKOUT_BUTTON_LABEL = 'PDP_PRICE_CARD_CONTINUE_CHECKOUT_BUTTON_LABEL',
  PDP_PRICE_CARD_BUY_OR_RESERVE_CAR_BUTTON_LABEL = 'PDP_PRICE_CARD_BUY_OR_RESERVE_CAR_BUTTON_LABEL',
  PDP_PRICE_CARD_ON_HOLD_CAR_NOTE = 'PDP_PRICE_CARD_ON_HOLD_CAR_NOTE',
  PDP_PRICE_CARD_ON_HOLD_CAR_LEARN_MORE = 'PDP_PRICE_CARD_ON_HOLD_CAR_LEARN_MORE',
  PDP_PRICE_CARD_CAR_PURCHASED_LABEL = 'PDP_PRICE_CARD_CAR_PURCHASED_LABEL',
  PDP_CAR_SUMMARY_NO_ANCAP_RATING_LABEL = 'PDP_CAR_SUMMARY_NO_ANCAP_RATING_LABEL',
  PDP_CAR_SUMMARY_SERVICE_NOT_REQUIRED_LABEL = 'PDP_CAR_SUMMARY_SERVICE_NOT_REQUIRED_LABEL',
  PDP_CAR_SUMMARY_HEADER = 'PDP_CAR_SUMMARY_HEADER',
  PDP_CAR_SUMMARY_SECTION_1_HEADER = 'PDP_CAR_SUMMARY_SECTION_1_HEADER',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_3',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_4 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_4',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_5 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_5',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_6 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_6',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_7 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_7',
  PDP_CAR_SUMMARY_SECTION_2_HEADER = 'PDP_CAR_SUMMARY_SECTION_2_HEADER',
  PDP_CAR_SUMMARY_SECTION_2_FOOTER = 'PDP_CAR_SUMMARY_SECTION_2_FOOTER',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_3',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_4 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_4',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_5 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_5',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_6 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_6',
  PDP_CAR_SUMMARY_SECTION_3_HEADER = 'PDP_CAR_SUMMARY_SECTION_3_HEADER',
  PDP_CAR_SUMMARY_SECTION_3_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_3_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_4_HEADER = 'PDP_CAR_SUMMARY_SECTION_4_HEADER',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_3',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_4 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_4',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_5 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_5',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_6 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_6',
  PDP_CAR_SUMMARY_TOW_CAP_BRAKED = 'PDP_CAR_SUMMARY_TOW_CAPACITY_BRAKED',
  PDP_CAR_SUMMARY_SECTION_5_HEADER = 'PDP_CAR_SUMMARY_SECTION_5_HEADER',
  PDP_CAR_SUMMARY_SECTION_5_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_5_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_5_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_5_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_5_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_5_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_HEADER = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_HEADER',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_HEADER = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_HEADER',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_FUEL_HEADER = 'PDP_CAR_SUMMARY_ITEM_FUEL_HEADER',
  PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_HEADER = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_HEADER',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_SIZES_HEADER = 'PDP_CAR_SUMMARY_ITEM_SIZES_HEADER',
  PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_3',
  PDP_CAR_FEATURES_HEADER = 'PDP_CAR_FEATURES_HEADER',
  PDP_CAR_FEATURES_ITEM_1_HEADER = 'PDP_CAR_FEATURES_ITEM_1_HEADER',
  PDP_CAR_FEATURES_ITEM_2_HEADER = 'PDP_CAR_FEATURES_ITEM_2_HEADER',
  PDP_CAR_FEATURES_ITEM_3_HEADER = 'PDP_CAR_FEATURES_ITEM_3_HEADER',
  PDP_CAR_FEATURES_BUTTON_SHOW_ALL_LABEL = 'PDP_CAR_FEATURES_BUTTON_SHOW_ALL_LABEL',
  PDP_PRICE_CARD_FINANCE = 'PDP_PRICE_CARD_FINANCE',
  PDP_PRICE_CARD_FINANCE_SHORT = 'PDP_PRICE_CARD_FINANCE_SHORT',
  PDP_PRICE_CARD_FINANCE_FOOTNOTE = 'PDP_PRICE_CARD_FINANCE_FOOTNOTE',
  PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK_MOBILE = 'PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK_MOBILE',
  PDP_PRICE_CARD_VIEW_PRICE_GUIDE = 'PDP_PRICE_CARD_VIEW_PRICE_GUIDE',
  PDP_PRICE_CARD_VIEW_PRICE_TEXT = 'PDP_PRICE_CARD_VIEW_PRICE_TEXT',
  PDP_PRICE_CARD_IN_PROGRESS_MOBILE_LABEL = 'PDP_PRICE_CARD_IN_PROGRESS_MOBILE_LABEL',
  PDP_PRICE_CARD_IN_PROGRESS_TABLET_LABEL = 'PDP_PRICE_CARD_IN_PROGRESS_TABLET_LABEL',
  PDP_PRICE_CARD_USE_FINANCE_DESCRIPTION = 'PDP_PRICE_CARD_USE_FINANCE_DESCRIPTION',
  PDP_PRICE_CARD_USE_FINANCE_HEADING = 'PDP_PRICE_CARD_USE_FINANCE_HEADING',
  PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK = 'PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK',
  PDP_PRICE_CARD_USE_FINANCE_REPAYMENT_DESCRIPTION = 'PDP_PRICE_CARD_USE_FINANCE_REPAYMENT_DESCRIPTION',
  PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_HEADLINE = 'PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_HEADLINE',
  PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_DESCRIPTION = 'PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_DESCRIPTION',
  PDP_PRICE_CARD_PAY_IN_FULL_CARD_TITLE = 'PDP_PRICE_CARD_CARD_TITLE',
  PDP_PRICE_CARD_PAY_IN_FULL_CARD_DESCRIPTION = 'PDP_PRICE_CARD_PAY_IN_FULL_CARD_DESCRIPTION',
  PDP_PRICE_CARD_PAY_IN_FULL_GOVT_CHARGES_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_GOVT_CHARGES_TEXT',
  PDP_PRICE_CARD_PAY_IN_FULL_LABEL_BUTTON = 'PDP_PRICE_CARD_PAY_IN_FULL_LABEL_BUTTON',
  PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_MASTER_CARD_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_MASTER_CARD_TEXT',
  PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_VISA_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_VISA_TEXT',
  PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_AMERICAN_EXPRESS_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_AMERICAN_EXPRESS_TEXT',
  PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFO = 'PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFO',
  PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFORMATION = 'PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFORMATION',
  PDP_IMAGES_TAKE_IT_FOR_A_SPIN = 'PDP_IMAGES_TAKE_IT_FOR_A_SPIN',
  PDP_PRICE_CARD_USP_ITEM_1 = 'PDP_PRICE_CARD_USP_ITEM_1',
  PDP_PRICE_CARD_USP_ITEM_2 = 'PDP_PRICE_CARD_USP_ITEM_2',
  PDP_PRICE_CARD_USP_ITEM_3 = 'PDP_PRICE_CARD_USP_ITEM_3',
  PLP_FILTER_MAKE_MODEL_ALL = 'PLP_FILTER_MAKE_MODEL_ALL',
  PLP_FILTER_MAKE_MODEL_SELECTED = 'PLP_FILTER_MAKE_MODEL_SELECTED',
  PLP_FILTER_MAKE_MODEL_SEARCH_LABEL = 'PLP_FILTER_MAKE_MODEL_SEARCH_LABEL',
  PLP_FILTER_MAKE_MODEL_SHOW_ALL = 'PLP_FILTER_MAKE_MODEL_SHOW_ALL',
  PLP_FILTER_MAKE_MODEL_SHOW_POPULAR = 'PLP_FILTER_MAKE_MODEL_SHOW_POPULAR',
  PDP_GALLERY_MODAL_QUALITY = 'PDP_GALLERY_MODAL_QUALITY',
  PDP_GALLERY_TITLE = 'PDP_GALLERY_TITLE',
  PDP_GALLERY_BUTTON_GALLERY = 'PDP_GALLERY_BUTTON_GALLERY',
  PDP_GALLERY_BUTTON_IMPERFECTION = 'PDP_GALLERY_BUTTON_IMPERFECTION',
  PDP_GALLERY_BUTTON_INTERIOR = 'PDP_GALLERY_BUTTON_INTERIOR',
  PDP_GALLERY_NO_IMPERFECTIONS = 'PDP_GALLERY_NO_IMPERFECTIONS',
  PDP_GALLERY_NO_IMPERFECTIONS_MOBILE = 'PDP_GALLERY_NO_IMPERFECTIONS_MOBILE',
  PDP_GALLERY_BUTTON_EXTERIOR = 'PDP_GALLERY_BUTTON_EXTERIOR',
  PDP_GALLERY_BUTTON_VIEW_360 = 'PDP_GALLERY_VIEW_360',
  PDP_CAR_HERO_BUTTON_OPEN_DOORS = 'PDP_CAR_HERO_OPEN_DOORS',
  PDP_CAR_HERO_BUTTON_CLOSE_DOORS = 'PDP_CAR_HERO_CLOSE_DOORS',
  PDP_IMPERFECTIONS_BUTTON_NEXT = 'PDP_IMPERFECTIONS_NEXT',
  PDP_IMPERFECTIONS_BUTTON_PREVIOUS = 'PDP_IMPERFECTIONS_PREVIOUS',
  PDP_WARRANTY_TITLE = 'PDP_WARRANTY_TITLE',
  PDP_WARRANTY_OTHER_BRANDS_TITLE = 'PDP_WARRANTY_OTHER_BRANDS_TITLE',
  PDP_WARRANTY_INFORMATION = 'PDP_WARRANTY_INFORMATION',
  PDP_WARRANTY_INFORMATION_UNLIMITED_KILOMETRES = 'PDP_WARRANTY_INFORMATION_UNLIMITED_KILOMETRES',
  PDP_WARRANTY_EXTENDED_MANUFACTURER = 'PDP_WARRANTY_EXTENDED_MANUFACTURER',
  PDP_REMAINING_WARRANTY_ELIGIBLE_CONTENT = 'PDP_REMAINING_WARRANTY_ELIGIBLE_CONTENT',
  PDP_ONLY_ELIGIBLE_CONTENT = 'PDP_ONLY_ELIGIBLE_CONTENT',
  PDP_WARRANTY_MODAL_LINK_LABEL = 'PDP_WARRANTY_MODAL_LINK_LABEL',
  PDP_WARRANTY_MODAL_LINK = 'PDP_WARRANTY_MODAL_LINK',
  PDP_CAR_ENQUIRY_CONTENT_BLOCK_TITLE = 'PDP_CAR_ENQUIRY_CONTENT_BLOCK_TITLE',
  PDP_CAR_ENQUIRY_CONTENT_BLOCK_DESCRIPTION = 'PDP_CAR_ENQUIRY_CONTENT_BLOCK_DESCRIPTION',
  PDP_CAR_ENQUIRY_FORM_HEADER = 'PDP_CAR_ENQUIRY_FORM_HEADER',
  PDP_CAR_ENQUIRY_SUCCESS_FORM_HEADER = 'PDP_CAR_ENQUIRY_SUCCESS_FORM_HEADER',
  PDP_CAR_ENQUIRY_FORM_MESSAGE_FIELD_LABEL = 'PDP_CAR_ENQUIRY_FORM_MESSAGE_FIELD_LABEL',
  PDP_CAR_ENQUIRY_FORM_FIRST_NAME_LABEL = 'PDP_CAR_ENQUIRY_FORM_FIRST_NAME_LABEL',
  PDP_CAR_ENQUIRY_FORM_LAST_NAME_LABEL = 'PDP_CAR_ENQUIRY_FORM_LAST_NAME_LABEL',
  PDP_CAR_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL = 'PDP_CAR_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL',
  PDP_CAR_ENQUIRY_FORM_PHONE_NUMBER_LABEL = 'PDP_CAR_ENQUIRY_FORM_PHONE_NUMBER_LABEL',
  PDP_CAR_ENQUIRY_FORM_TRADEIN_INTENT_LABEL_LABEL = 'PDP_CAR_ENQUIRY_FORM_TRADEIN_INTENT_LABEL_LABEL',
  PDP_CAR_ENQUIRY_FORM_IS_FINANCE_INTEREST_LABEL = 'PDP_CAR_ENQUIRY_FORM_IS_FINANCE_INTEREST_LABEL',
  PDP_CAR_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL = 'PDP_CAR_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL',
  PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE = 'PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE',
  PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE_BUTTON_LABEL = 'PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE_BUTTON_LABEL',
  PDP_CAR_ENQUIRY_FORM_DEFAULT_MESSAGE = 'PDP_CAR_ENQUIRY_FORM_DEFAULT_MESSAGE',
  PDP_CAR_ENQUIRY_FORM_ERROR_MESSAGE = 'PDP_CAR_ENQUIRY_FORM_ERROR_MESSAGE',
  PDP_FINANCE_ENQUIRY_FORM_HEADER = 'PDP_FINANCE_ENQUIRY_FORM_HEADER',
  PDP_FINANCE_ENQUIRY_FORM_MESSAGE_FIELD_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_MESSAGE_FIELD_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_DETAIL_SECTION_TITLE = 'PDP_FINANCE_ENQUIRY_FORM_DETAIL_SECTION_TITLE ',
  PDP_FINANCE_ENQUIRY_FORM_FIRST_NAME_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_FIRST_NAME_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_LAST_NAME_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_LAST_NAME_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_PHONE_NUMBER_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_PHONE_NUMBER_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_ERROR_MESSAGE = 'PDP_FINANCE_ENQUIRY_FORM_ERROR_MESSAGE',
  PDP_HEADER_SHARE_BUTTON_LABEL = 'PDP_HEADER_SHARE_BUTTON_LABEL',
  PDP_CAR_DETAILS_USP_COLUMN_1_LABEL = 'PDP_CAR_DETAILS_USP_COLUMN_1_LABEL',
  PDP_CAR_DETAILS_USP_COLUMN_2_LABEL = 'PDP_CAR_DETAILS_USP_COLUMN_2_LABEL',
  PDP_CAR_DETAILS_USP_COLUMN_3_LABEL = 'PDP_CAR_DETAILS_USP_COLUMN_3_LABEL',
  PDP_CAR_DETAILS_USP_COLUMN_4_LABEL = 'PDP_CAR_DETAILS_USP_COLUMN_4_LABEL',
  PDP_INSPECTION_LIST_TITLE = 'PDP_INSPECTION_LIST_TITLE',
  PDP_INSPECTION_LIST_DESCRIPTION = 'PDP_INSPECTION_LIST_DESCRIPTION',
  PDP_INSPECTION_LIST_ITEM_1 = 'PDP_INSPECTION_LIST_ITEM_1',
  PDP_INSPECTION_LIST_ITEM_2 = 'PDP_INSPECTION_LIST_ITEM_2',
  PDP_INSPECTION_LIST_ITEM_3 = 'PDP_INSPECTION_LIST_ITEM_3',
  PDP_INSPECTION_LIST_ITEM_4 = 'PDP_INSPECTION_LIST_ITEM_4',
  PDP_INSPECTION_LIST_ITEM_5 = 'PDP_INSPECTION_LIST_ITEM_5',
  PDP_INSPECTION_LIST_ITEM_6 = 'PDP_INSPECTION_LIST_ITEM_6',
  PDP_INSPECTION_LIST_ITEM_7 = 'PDP_INSPECTION_LIST_ITEM_7',
  PDP_INSPECTION_LIST_ITEM_8 = 'PDP_INSPECTION_LIST_ITEM_8',
  PDP_INSPECTION_LIST_CTA = 'PDP_INSPECTION_LIST_CTA',
  PDP_GET_STARTED_TITLE = 'PDP_GET_STARTED_TITLE',
  PDP_GET_STARTED_DESCRIPTION = 'PDP_GET_STARTED_DESCRIPTION',
  PDP_GET_STARTED_ITEM_1 = 'PDP_GET_STARTED_ITEM_1',
  PDP_GET_STARTED_ITEM_2 = 'PDP_GET_STARTED_ITEM_2',
  PDP_GET_STARTED_ITEM_3 = 'PDP_GET_STARTED_ITEM_3',
  PDP_CAR_OVERVIEW_TITLE = 'PDP_CAR_OVERVIEW_TITLE',
  PDP_KEY_INFORMATION_TITLE = 'PDP_KEY_INFORMATION_TITLE',
  PDP_KEY_INFORMATION_KILOMETERS = 'PDP_KEY_INFORMATION_KILOMETERS',
  PDP_KEY_INFORMATION_COMPLIANCE_YEAR = 'PDP_KEY_INFORMATION_COMPLIANCE_YEAR',
  PDP_KEY_INFORMATION_BUILD_YEAR = 'PDP_KEY_INFORMATION_BUILD_YEAR',
  PDP_KEY_INFORMATION_ENGINE = 'PDP_KEY_INFORMATION_ENGINE',
  PDP_KEY_INFORMATION_FUEL_TYPE = 'PDP_KEY_INFORMATION_FUEL_TYPE',
  PDP_KEY_INFORMATION_TRANSMISSION = 'PDP_KEY_INFORMATION_TRANSMISSION',
  PDP_KEY_INFORMATION_SEATS = 'PDP_KEY_INFORMATION_SEATS',
  PDP_KEY_INFORMATION_LAST_SERVICE_DATE = 'PDP_KEY_INFORMATION_LAST_SERVICE_DATE',
  PDP_KEY_INFORMATION_VIN_NUMBER = 'PDP_KEY_INFORMATION_VIN_NUMBER',
  PDP_CART_SWITCH_TITLE = 'PDP_CART_SWITCH_TITLE',
  PDP_CART_SWITCH_DESCRIPTION = 'PDP_CART_SWITCH_DESCRIPTION',
  PDP_CART_SWITCH_NEW_PURCHASE = 'PDP_CART_SWITCH_NEW_PURCHASE',
  PDP_CART_SWITCH_EXISTING_PURCHASE = 'PDP_CART_SWITCH_EXISTING_PURCHASE',
  PDP_SIMILAR_CARS_TITLE = 'PDP_SIMILAR_CARS_TITLE',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_1 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_1',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_2 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_2',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_3 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_3',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_4 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_4',
  PDP_CAR_OVERVIEW_REGO = 'PDP_CAR_OVERVIEW_REGO',
  PDP_CAR_OVERVIEW_LOW_KM_YEAR = 'PDP_CAR_OVERVIEW_LOW_KM_YEAR',
  PDP_CAR_OVERVIEW_LOW_KM = 'PDP_CAR_OVERVIEW_LOW_KM',
  PDP_CAR_OVERVIEW_LAST_SERVICED = 'PDP_CAR_OVERVIEW_LAST_SERVICED',
  PDP_CAR_OVERVIEW_SINGLE_OWNER = 'PDP_CAR_OVERVIEW_SINGLE_OWNER',
  PDP_CAR_OVERVIEW_TWO_NEW_TYRES = 'PDP_CAR_OVERVIEW_TWO_NEW_TYRES',
  PDP_CAR_OVERVIEW_FOUR_NEW_TYRES = 'PDP_CAR_OVERVIEW_FOUR_NEW_TYRES',
  PDP_CARMA_COLLECT_LOCATIONS_TITLE = 'PDP_CARMA_COLLECT_LOCATIONS_TITLE',
  PDP_CARMA_COLLECT_LOCATIONS_RADIOS_TITLE = 'PDP_CARMA_COLLECT_LOCATIONS_RADIOS_TITLE',
  PDP_CAR_OVERVIEW_FULL_SPARE_TYRE = 'PDP_CAR_OVERVIEW_FULL_SPARE_TYRE',
  PDP_SPECS_AND_FEATURES_HEADER = 'PDP_SPECS_AND_FEATURES_HEADER',
  PDP_SPECS_AND_FEATURES_CTA = 'PDP_SPECS_AND_FEATURES_CTA',
  PDP_FEATURES_HEADER = 'PDP_FEATURES_HEADER',
  PDP_SPECS_HEADER = 'PDP_SPECS_HEADER',
  PDP_FEATURES_CTA = 'PDP_FEATURES_CTA',
  PDP_SPECS_CTA = 'PDP_SPECS_CTA',
  PDP_SERVICE_HISTORY_HEADER = 'PDP_SERVICE_HISTORY_HEADER',
  PDP_SERVICE_HISTORY_SECTION_HEADER = 'PDP_SERVICE_HISTORY_SECTION_HEADER',
  PDP_SERVICE_HISTORY_ONE_SERVICE_HEADER = 'PDP_SERVICE_HISTORY_ONE_SERVICE_HEADER',
  PDP_SERVICE_HISTORY_LIKE_NEW_HEADER = 'PDP_SERVICE_HISTORY_LIKE_NEW_HEADER',
  PDP_SERVICE_HISTORY_BUTTON_SHOW_LABEL = 'PDP_SERVICE_HISTORY_BUTTON_SHOW_LABEL',
  PDP_SERVICE_HISTORY_BUTTON_HIDE_LABEL = 'PDP_SERVICE_HISTORY_BUTTON_HIDE_LABEL',
  PDP_SERVICE_HISTORY_NO_SERVICE_DATE_MESSAGE = 'PDP_SERVICE_HISTORY_NO_SERVICE_DATE_MESSAGE',
  PDP_SERVICE_HISTORY_NO_KILOMETRE_MESSAGE = 'PDP_SERVICE_HISTORY_NO_KILOMETRE_MESSAGE',
  PDP_TRADE_IN_HEADER = 'PDP_TRADE_IN_HEADER',
  PDP_TRADE_IN_DESCRIPTION = 'PDP_TRADE_IN_DESCRIPTION',
  PDP_TRADE_IN_BUTTON_SUBMIT_LABEL = 'PDP_TRADE_IN_BUTTON_SUBMIT_LABEL',
  //
  //
  // Trade-in form microcopy
  //
  //
  TRADE_IN_CAR_FOUND_TITLE = 'TRADE_IN_CAR_FOUND_TITLE',
  TRADE_IN_CAR_FOUND_DESCRIPTION = 'TRADE_IN_CAR_FOUND_DESCRIPTION',
  TRADE_IN_CAR_FOUND_HINT = 'TRADE_IN_CAR_DETAILS_HINT',
  TRADE_IN_CAR_FOUND_FIELD_DETAILS_CORRECT = 'HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_FIELD_DETAILS_CORRECT',
  TRADE_IN_CAR_NOT_FOUND_TITLE = 'TRADE_IN_CAR_NOT_FOUND_TITLE',
  TRADE_IN_EDIT_CAR_DETAILS_TITLE = 'TRADE_IN_EDIT_CAR_DETAILS_TITLE',
  TRADE_IN_PERSONAL_INFO_TITLE = 'TRADE_IN_PERSONAL_INFO_TITLE',
  TRADE_IN_PERSONAL_INFO_DESCRIPTION = 'TRADE_IN_PERSONAL_INFO_DESCRIPTION',
  TRADE_IN_INTRO_TITLE = 'TRADE_IN_INTRO_TITLE',
  TRADE_IN_INTRO_CONTENT = 'TRADE_IN_INTRO_CONTENT',
  TRADE_IN_BASIC_INFO_TITLE = 'TRADE_IN_BASIC_INFO_TITLE',
  TRADE_IN_TIMELINE_TITLE = 'TRADE_IN_TIMELINE_TITLE',
  TRADE_IN_TYRE_WEAR_TITLE = 'TRADE_IN_TYRE_WEAR_TITLE',
  TRADE_IN_VALUATION_TITLE = 'TRADE_IN_VALUATION_TITLE',
  TRADE_IN_EXTERIOR_TITLE = 'TRADE_IN_EXTERIOR_TITLE',
  TRADE_IN_EXTERIOR_FIELD_LABEL = 'TRADE_IN_EXTERIOR_FIELD_LABEL',
  TRADE_IN_EXTERIOR_FIELD_SUBLABEL = 'TRADE_IN_EXTERIOR_FIELD_SUBLABEL',
  TRADE_IN_EXTERIOR_HAIL_TITLE = 'TRADE_IN_EXTERIOR_HAIL_TITLE',
  TRADE_IN_EXTERIOR_HAIL_LABEL = 'TRADE_IN_EXTERIOR_HAIL_LABEL',
  TRADE_IN_EXTERIOR_EXTERIOR_DAMAGE_TITLE = 'TRADE_IN_EXTERIOR_EXTERIOR_DAMAGE_TITLE',
  TRADE_IN_EXTERIOR_MINOR_DAMAGE_LABEL = 'TRADE_IN_EXTERIOR_MINOR_DAMAGE_LABEL',
  TRADE_IN_EXTERIOR_MINOR_DAMAGE_SUBLABEL = 'TRADE_IN_EXTERIOR_MINOR_DAMAGE_SUBLABEL',
  TRADE_IN_EXTERIOR_MAJOR_DAMAGE_LABEL = 'TRADE_IN_EXTERIOR_MAJOR_DAMAGE_LABEL',
  TRADE_IN_EXTERIOR_MAJOR_DAMAGE_SUBLABEL = 'TRADE_IN_EXTERIOR_MAJOR_DAMAGE_SUBLABEL',
  TRADE_IN_EXTERIOR_WHEEL_DAMAGE_TITLE = 'TRADE_IN_EXTERIOR_WHEEL_DAMAGE_TITLE',
  TRADE_IN_EXTERIOR_WHEEL_DAMAGE_LABEL = 'TRADE_IN_EXTERIOR_WHEEL_DAMAGE_LABEL',
  TRADE_IN_EXTERIOR_WINDSCREEN_TITLE = 'TRADE_IN_EXTERIOR_WINDSCREEN_TITLE',
  TRADE_IN_EXTERIOR_WINDSCREEN_CRACKS_LABEL = 'TRADE_IN_EXTERIOR_WINDSCREEN_CRACKS_LABEL',
  TRADE_IN_EXTERIOR_WINDSCREEN_CHIPS_LABEL = 'TRADE_IN_EXTERIOR_WINDSCREEN_CHIPS_LABEL',
  TRADE_IN_INTERIOR_TITLE = 'TRADE_IN_INTERIOR_TITLE',
  TRADE_IN_INTERIOR_RIPS_TEARS_TITLE = 'TRADE_IN_INTERIOR_RIPS_TEARS_TITLE',
  TRADE_IN_INTERIOR_STAINS_TITLE = 'TRADE_IN_INTERIOR_STAINS_TITLE',
  TRADE_IN_INTERIOR_FIELD_LABEL = 'TRADE_IN_INTERIOR_FIELD_LABEL',
  TRADE_IN_INTERIOR_FIELD_SUBLABEL = 'TRADE_IN_INTERIOR_FIELD_SUBLABEL',
  TRADE_IN_INTERIOR_DAMAGE_TO_PANEL_OR_DASHBOARD = 'TRADE_IN_INTERIOR_DAMAGE_TO_PANEL_OR_DASHBOARD',
  TRADE_IN_INTERIOR_DIGITAL_SCREEN_TITLE = 'TRADE_IN_INTERIOR_DIGITAL_SCREEN_TITLE',
  TRADE_IN_INTERIOR_ODOUR_TITLE = 'TRADE_IN_INTERIOR_ODOUR_TITLE',
  TRADE_IN_MISSED_ANYTHING_TITLE = 'TRADE_IN_MISSED_ANYTHING_TITLE',
  TRADE_IN_MISSED_ANYTHING_SUBTITLE = 'TRADE_IN_MISSED_ANYTHING_SUBTITLE',
  TRADE_IN_FACTORY_OPTIONS_TITLE = 'TRADE_IN_FACTORY_OPTIONS_TITLE',
  TRADE_IN_FACTORY_OPTIONS_LABEL = 'TRADE_IN_FACTORY_OPTIONS_LABEL',
  TRADE_IN_FACTORY_OPTIONS_SUBLABEL = 'TRADE_IN_FACTORY_OPTIONS_SUBLABEL',
  TRADE_IN_APPLICABLE_OPTIONS_LABEL = 'TRADE_IN_APPLICABLE_OPTIONS_LABEL',
  TRADE_IN_APPLICABLE_OPTIONS_SUBLABEL = 'TRADE_IN_APPLICABLE_OPTIONS_SUBLABEL',
  TRADE_IN_OTHER_OPTIONAL_EXTRAS_LABEL = 'TRADE_IN_OTHER_OPTIONAL_EXTRAS_LABEL',
  TRADE_IN_MODIFICATIONS_TITLE = 'TRADE_IN_MODIFICATIONS_TITLE',
  TRADE_IN_MODIFICATIONS_RADIO_FIELD_LABEL = 'TRADE_IN_MODIFICATIONS_RADIO_FIELD_LABEL',
  TRADE_IN_MODIFICATIONS_RADIO_FIELD_SUBLABEL = 'TRADE_IN_MODIFICATIONS_RADIO_FIELD_SUBLABEL',
  TRADE_IN_MODIFICATIONS_TAG_FIELD_LABEL = 'TRADE_IN_MODIFICATIONS_TAG_FIELD_LABEL',
  TRADE_IN_SERVICE_HISTORY_TITLE = 'TRADE_IN_SERVICE_HISTORY_TITLE',
  TRADE_IN_SERVICE_HISTORY_LABEL = 'TRADE_IN_SERVICE_HISTORY_LABEL',
  TRADE_IN_SERVICE_HISTORY_SUBLABEL = 'TRADE_IN_SERVICE_HISTORY_SUBLABEL',
  TRADE_IN_SERVICE_COMPLETED_FRANCHISED_LABEL = 'TRADE_IN_SERVICE_COMPLETED_FRANCHISED_LABEL',
  TRADE_IN_SERVICE_COMPLETED_FRANCHISED_SUBLABEL = 'TRADE_IN_SERVICE_COMPLETED_FRANCHISED_SUBLABEL',
  TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_LABEL = 'TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_LABEL',
  TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_SUBLABEL = 'TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_SUBLABEL',
  TRADE_IN_SERVICE_MISSING_LABEL = 'TRADE_IN_SERVICE_MISSING_LABEL',
  TRADE_IN_CAR_HISTORY_TITLE = 'TRADE_IN_CAR_HISTORY_TITLE',
  TRADE_IN_CAR_HISTORY_FIRST_OWNER_LABEL = 'TRADE_IN_CAR_HISTORY_FIRST_OWNER_LABEL',
  TRADE_IN_CAR_HISTORY_HOW_MANY_KEY_LABEL = 'TRADE_IN_CAR_HISTORY_HOW_MANY_KEY_LABEL',
  TRADE_IN_CAR_HISTORY_IN_AN_ACCIDENT_LABEL = 'TRADE_IN_CAR_HISTORY_IN_AN_ACCIDENT_LABEL',
  TRADE_IN_PHOTOS_TITLE = 'TRADE_IN_PHOTOS_TITLE',
  TRADE_IN_PHOTOS_DESCRIPTION = 'TRADE_IN_PHOTOS_DESCRIPTION',
  TRADE_IN_CAR_PHOTOS_LABEL = 'TRADE_IN_CAR_PHOTOS_LABEL',
  TRADE_IN_OPTIONS_ACCESSORIES_PHOTOS_LABEL = 'TRADE_IN_OPTIONS_ACCESSORIES_PHOTOS_LABEL',
  TRADE_IN_QR_TITLE = 'TRADE_IN_QR_TITLE',
  TRADE_IN_QR_DESCRIPTION = 'TRADE_IN_QR_DESCRIPTION',
  TRADE_IN_EMAIL_LATER_TITLE = 'TRADE_EMAIL_LATER_TITLE',
  TRADE_IN_EMAIL_LATER_DESCRIPTION = 'TRADE_IN_EMAIL_LATER_DESCRIPTION',
  TRADE_IN_EMAIL_LATER_LINK_LABEL = 'TRADE_IN_EMAIL_LATER_LINK_LABEL',
  TRADE_IN_THANK_YOU_HEADER = 'TRADE_IN_THANK_YOU_HEADER',
  TRADE_IN_THANK_YOU_DESCRIPTION_NEXT = 'TRADE_IN_THANK_YOU_DESCRIPTION_NEXT',
  TRADE_IN_THANK_YOU_DESCRIPTION_MEANWHILE = 'TRADE_IN_THANK_YOU_DESCRIPTION_MEANWHILE',

  //
  //
  // Checkout microcopy
  //
  //
  CHECKOUT_EXPIRED_MODAL_TITLE = 'CHECKOUT_EXPIRED_MODAL_TITLE',
  CHECKOUT_EXPIRED_MODAL_DESCRIPTION = 'CHECKOUT_EXPIRED_MODAL_DESCRIPTION',
  CHECKOUT_EXPIRED_MODAL_BUTTON_1_LABEL = 'CHECKOUT_EXPIRED_MODAL_BUTTON_1_LABEL',
  CHECKOUT_EXPIRED_MODAL_BUTTON_2_LABEL = 'CHECKOUT_EXPIRED_MODAL_BUTTON_2_LABEL',
  CHECKOUT_TRADE_IN_HELP = 'CHECKOUT_TRADE_IN_HELP',
  CHECKOUT_FINANCE_HAS_TRADE_IN_CARMA = 'CHECKOUT_FINANCE_HAS_TRADE_IN_CARMA',
  CHECKOUT_FINANCE_HAS_TRADE_IN_PAY_WITH_FUNDS = 'CHECKOUT_FINANCE_HAS_TRADE_IN_PAY_WITH_FUNDS',
  CHECKOUT_TRADE_IN_TITLE = 'CHECKOUT_TRADE_IN_TITLE',
  CHECKOUT_TRADE_IN_HEADER = 'CHECKOUT_TRADE_IN_HEADER',
  CHECKOUT_TRADE_IN_CONTENT = 'CHECKOUT_TRADE_IN_CONTENT',
  CHECKOUT_TRADE_IN_BANNER_ITEM_1 = 'CHECKOUT_TRADE_IN_BANNER_ITEM_1',
  CHECKOUT_TRADE_IN_BANNER_ITEM_2 = 'CHECKOUT_TRADE_IN_BANNER_ITEM_2',
  CHECKOUT_TRADE_IN_RADIO_LABEL = 'CHECKOUT_TRADE_IN_RADIO_LABEL',
  CHECKOUT_TRADE_IN_RADIO_ITEM_1_LABEL = 'CHECKOUT_TRADE_IN_RADIO_ITEM_1_LABEL',
  CHECKOUT_TRADE_IN_RADIO_ITEM_2_LABEL = 'CHECKOUT_TRADE_IN_RADIO_ITEM_2_LABEL',
  CHECKOUT_TRADE_IN_BUTTON_LABEL = 'CHECKOUT_TRADE_IN_BUTTON_LABEL',
  CHECKOUT_TRADE_IN_DESCRIPTION = 'CHECKOUT_TRADE_IN_DESCRIPTION',
  CHECKOUT_STEP_1_LABEL_CONTENT = 'CHECKOUT_STEP_1_LABEL_CONTENT',
  CHECKOUT_STEP_1_CAPTION_CONTENT = 'CHECKOUT_STEP_1_CAPTION_CONTENT',
  CHECKOUT_STEP_2_LABEL_CONTENT = 'CHECKOUT_STEP_2_LABEL_CONTENT',
  CHECKOUT_STEP_2_CAPTION_CONTENT = 'CHECKOUT_STEP_2_CAPTION_CONTENT',
  CHECKOUT_STEP_3_LABEL_CONTENT = 'CHECKOUT_STEP_3_LABEL_CONTENT',
  CHECKOUT_STEP_3_CAPTION_CONTENT = 'CHECKOUT_STEP_3_CAPTION_CONTENT',
  CHECKOUT_STEP_4_LABEL_CONTENT = 'CHECKOUT_STEP_4_LABEL_CONTENT',
  CHECKOUT_STEP_4_CAPTION_CONTENT = 'CHECKOUT_STEP_4_CAPTION_CONTENT',
  CHECKOUT_STEP_5_LABEL_CONTENT = 'CHECKOUT_STEP_5_LABEL_CONTENT',
  CHECKOUT_STEP_5_CAPTION_CONTENT = 'CHECKOUT_STEP_5_CAPTION_CONTENT',
  CHECKOUT_STEP_6_LABEL_CONTENT = 'CHECKOUT_STEP_6_LABEL_CONTENT',
  CHECKOUT_STEP_6_CAPTION_CONTENT = 'CHECKOUT_STEP_6_CAPTION_CONTENT',
  CHECKOUT_CONFIRMATION_TRADE_IN_CARD = 'CHECKOUT_CONFIRMATION_TRADE_IN_CARD',
  CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_TITLE = 'CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_TITLE',
  CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_LIST = 'CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_LIST',
  CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_DESCRIPTION = 'CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_DESCRIPTION',
  CHECKOUT_CONFIRMATION_PAID_FULL_TITLE = 'CHECKOUT_CONFIRMATION_PAID_FULL_TITLE',
  CHECKOUT_CONFIRMATION_RESERVATION_TITLE = 'CHECKOUT_CONFIRMATION_RESERVATION_TITLE',
  CHECKOUT_CONFIRMATION_PAID_FULL_DESCRIPTION = 'CHECKOUT_CONFIRMATION_PAID_FULL_DESCRIPTION',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_1 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_1',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_2 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_2',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_3 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_3',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_4 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_4',
  CHECKOUT_CONFIRMATION_DELIVERY_TIME_DESCRIPTION = 'CHECKOUT_CONFIRMATION_DELIVERY_TIME_DESCRIPTION',
  CHECKOUT_CONFIRMATION_COLLECTION_TIME_DESCRIPTION = 'CHECKOUT_CONFIRMATION_COLLECTION_TIME_DESCRIPTION',
  CHECKOUT_CONFIRMATION_DELIVERY_ADDRESS_DESCRIPTION = 'CHECKOUT_CONFIRMATION_DELIVERY_ADDRESS_DESCRIPTION',
  CHECKOUT_CONFIRMATION_COLLECTION_DESCRIPTION = 'CHECKOUT_CONFIRMATION_COLLECTION_DESCRIPTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_COLLECTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_COLLECTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_COLLECTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_COLLECTION',
  CHECKOUT_CONFIRMATION_DELIVERY_NOTICE = 'CHECKOUT_CONFIRMATION_DELIVERY_CONTACT_NOTICE',
  CHECKOUT_CONFIRMATION_COLLECTION_NOTICE = 'CHECKOUT_CONFIRMATION_COLLECTION_NOTICE',
  CHECKOUT_CONFIRMATION_CARMA_DETAILS = 'CHECKOUT_CONFIRMATION_CARMA_DETAILS',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_TITLE = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_TITLE',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_1 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_1',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_2 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_2',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_3 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_3',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_4 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_4',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_1 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_1',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_2 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_2',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_3 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_3',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_4 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_4',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_5 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_5',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_6 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_6',
  CHECKOUT_CONFIRMATION_CTA = 'CHECKOUT_CONFIRMATION_CTA',
  CHECKOUT_DESCRIPTION_TERM_POLICY_DESCRIPTION = 'CHECKOUT_DESCRIPTION_TERM_POLICY_DESCRIPTION',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_ALLICATION_TYPE = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_ALLICATION_TYPE',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_RESIDENTIAL_STATUS = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_RESIDENTIAL_STATUS',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_FINANCE_DEPOSIT_AMOUNT = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_FINANCE_DEPOSIT_AMOUNT',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_LESS_THAN_3000 = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_LESS_THAN_3000',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_MORE_THAN_200000 = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_LESS_THAN_200000',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_TERM_OF_LOAN = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_TERM_OF_LOAN',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_REPAYMENT_FREQUENCY = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_REPAYMENT_FREQUENCY',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_YEAR = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_YEAR',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_MONTH = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_MONTH',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_DATE_PICKER = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_DATE_PICKER',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_LESS_THAN_18_YEAR_OLD = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_LESS_THAN_18_YEAR_OLD',
  CHECKOUT_HEADER_TITLE = 'CHECKOUT_HEADER_TITLE',
  CHECKOUT_HEADER_VIEW_DETAILS = 'CHECKOUT_HEADER_VIEW_DETAILS',
  CHECKOUT_HEADER_HIDE_DETAILS = 'CHECKOUT_HEADER_HIDE_DETAILS',
  CHECKOUT_CHECKOUT_CAR_CARD_PRICE_LABEL = 'CHECKOUT_CHECKOUT_CAR_CARD_PRICE_LABEL',
  CHECKOUT_CHECKOUT_CAR_CARD_PRICE_GST_LABEL = 'CHECKOUT_CHECKOUT_CAR_CARD_PRICE_GST_LABEL',
  CHECKOUT_FINANCE_LOAN_CALCULATOR_TITLE = 'CHECKOUT_FINANCE_LOAN_CALCULATOR_TITLE',
  CHECKOUT_FINANCE_LOAN_CALCULATOR_DESCRIPTION = 'CHECKOUT_FINANCE_LOAN_CALCULATOR_DESCRIPTION',
  CHECKOUT_FOOTER_LINK_1 = 'CHECKOUT_FOOTER_LINK_1',
  CHECKOUT_FOOTER_LINK_2 = 'CHECKOUT_FOOTER_LINK_2',
  CHECKOUT_FOOTER_LINK_3 = 'CHECKOUT_FOOTER_LINK_3',
  CHECKOUT_STEP3_PAYMENT_OPTIONS_CHOOSE_FINANCIER = 'CHECKOUT_STEP3_PAYMENT_OPTIONS_CHOOSE_FINANCIER',
  CHECKOUT_STEP3_PAYMENT_OPTIONS_SKIP_FINANCIER = 'CHECKOUT_STEP3_PAYMENT_OPTIONS_SKIP_FINANCIER',
  CHECKOUT_STEP3_PAYMENT_OPTIONS_NEXT_BUTTON = 'CHECKOUT_STEP3_PAYMENT_OPTIONS_NEXT_BUTTON',
  CHECKOUT_PAYMENT_OPTIONS_HEADER = 'CHECKOUT_PAYMENT_OPTIONS_HEADER',
  CHECKOUT_PAYMENT_OPTIONS_CONTENT = 'CHECKOUT_PAYMENT_OPTIONS_CONTENT',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_1_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_1_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_2_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_2_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_3_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_3_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_QUOTE = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_QUOTE',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_FINANCE = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_FINANCE',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_SUMMARY = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_SUMMARY',
  CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_BSB = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_BSB',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_TITLE = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_TITLE',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CAPTION = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CAPTION',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_HELP_TEXT = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_HELP_TEXT',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_SUBTITLE = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_SUBTITLE',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_ACC_NUMBER = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_ACC_NUMBER',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_PAID_BUTTON_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_PAID_BUTTON_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CLOSE_BUTTON_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CLOSE_BUTTON_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_PROCESSING_FEE_TITLE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_PROCESSING_FEE_TITLE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BANK_TRANSFER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BANK_TRANSFER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_FREE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_FREE',
  CHECKOUT_STEP_BUY_TYPE_HEADER = 'CHECKOUT_STEP_BUY_TYPE_HEADER',
  CHECKOUT_STEP_BUY_TYPE_DESCRIPTION = 'CHECKOUT_STEP_BUY_TYPE_DESCRIPTION',
  CHECKOUT_STEP_BUY_TYPE_LABEL = 'CHECKOUT_STEP_BUY_TYPE_LABEL',
  CHECKOUT_STEP_BUY_TYPE_OPTION_PERSONAL = 'CHECKOUT_STEP_BUY_TYPE_OPTION_PERSONAL',
  CHECKOUT_STEP_BUY_TYPE_OPTION_BUSINESS = 'CHECKOUT_STEP_BUY_TYPE_OPTION_BUSINESS',
  CHECKOUT_STEP_BUY_TYPE_BUTTON_PERSONAL = 'CHECKOUT_STEP_BUY_TYPE_BUTTON_PERSONAL',
  CHECKOUT_STEP_BUY_TYPE_BUTTON_BUSINESS = 'CHECKOUT_STEP_BUY_TYPE_BUTTON_BUSINESS',
  CHECKOUT_STEP_BUSINESS_INFO_HEADER = 'CHECKOUT_STEP_BUSINESS_INFO_HEADER',
  CHECKOUT_STEP_BUSINESS_INFO_DESCRIPTION = 'CHECKOUT_STEP_BUSINESS_INFO_DESCRIPTION',
  CHECKOUT_STEP_BUSINESS_INFO_ABN_LABEL = 'CHECKOUT_STEP_BUSINESS_INFO_ABN_LABEL',
  CHECKOUT_STEP_BUSINESS_INFO_ABN_HELP = 'CHECKOUT_STEP_BUSINESS_INFO_ABN_HELP',
  CHECKOUT_FINANCE_QUOTE_FORM_RESIDENT_TYPE_TITLE = 'CHECKOUT_FINANCE_QUOTE_FORM_RESIDENT_TYPE_TITLE',
  CHECKOUT_FINANCE_QUOTE_FORM_FAMILY_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_FAMILY_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_RESIDENTIAL_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_RESIDENTIAL_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_EMPLOYMENT_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_EMPLOYMENT_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_FORM_BUTTON_LABEL',
  CHECKOUT_FINANCE_QUOTE_FORM_TERM_CONDITIONS = 'CHECKOUT_FINANCE_QUOTE_FORM_TERM_CONDITIONS',
  CHECKOUT_FINANCE_QUOTE_FORM_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_CONTENT = 'CHECKOUT_FINANCE_QUOTE_FORM_CONTENT',
  CHECKOUT_FINANCE_QUOTE_CTA = 'CHECKOUT_FINANCE_QUOTE_CTA',
  CHECKOUT_FINANCE_QUOTE_NOTE = 'CHECKOUT_FINANCE_QUOTE_NOTE',
  CHECKOUT_FINANCE_QUOTE_NOTE_PAYMENT_SUMMARY = 'CHECKOUT_FINANCE_QUOTE_NOTE_PAYMENT_SUMMARY',
  CHECKOUT_FINANCE_QUOTE_UPDATE_FINANCE_INFO_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_UPDATE_FINANCE_INFO_BUTTON_LABEL',
  CHECKOUT_FINANCE_QUOTE_FEE_LABEL = 'CHECKOUT_FINANCE_QUOTE_FEE_LABEL',
  CHECKOUT_FINANCE_QUOTE_DETAILED_SETUP_FEES = 'CHECKOUT_FINANCE_QUOTE_DETAILED_SETUP_FEES',
  CHECKOUT_FINANCE_QUOTE_DETAILED_MONTHLY_FEES = 'CHECKOUT_FINANCE_QUOTE_DETAILED_MONTHLY_FEES',
  CHECKOUT_FINANCE_QUOTE_DETAILED_EARLY_TERMINATION_FEES = 'CHECKOUT_FINANCE_QUOTE_DETAILED_EARLY_TERMINATION_FEES',
  CHECKOUT_FINANCE_QUOTE_PROVIDER_CARMA = 'CHECKOUT_FINANCE_QUOTE_PROVIDER_CARMA',
  CHECKOUT_FINANCE_QUOTE_LOADING_LABEL = 'CHECKOUT_FINANCE_QUOTE_LOADING_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_IMPORTANT_INFORMATION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_IMPORTANT_INFORMATION',
  CHECKOUT_FINANCE_QUOTE_FEE_BREAKDOWN_CTA = 'CHECKOUT_FINANCE_QUOTE_FEE_BREAKDOWN_CTA',
  CHECKOUT_FINANCE_QUOTE_FREQUENCY_SWITCH_HEADER = 'CHECKOUT_FINANCE_QUOTE_FREQUENCY_SWITCH_HEADER',
  CHECKOUT_FINANCE_QUOTE_FREQUENCY_WEEKLY = 'CHECKOUT_FINANCE_QUOTE_FREQUENCY_WEEKLY',
  CHECKOUT_FINANCE_QUOTE_FREQUENCY_MONTHLY = 'CHECKOUT_FINANCE_QUOTE_FREQUENCY_MONTHLY',
  CHECKOUT_FINANCE_QUOTE_LOAN_TERM_HEADER = 'CHECKOUT_FINANCE_QUOTE_LOAN_TERM_HEADER',
  CHECKOUT_FINANCE_QUOTE_LOAN_TERM_NO_QUOTE_AVAILABLE_HEADER = 'CHECKOUT_FINANCE_QUOTE_LOAN_TERM_NO_QUOTE_AVAILABLE_HEADER',
  CHECKOUT_FINANCE_QUOTES_ALLIED_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_ALLIED_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_ALLIED_PERSONAL_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_ALLIED_PERSONAL_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_ALLIED_BUSINESS_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_ALLIED_BUSINESS_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_LOAN_TERM_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_LOAN_TERM_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_UNAVAILABLE_LABEL = 'CHECKOUT_FINANCE_QUOTE_UNAVAILABLE_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_MODAL_HEADER = 'CHECKOUT_FINANCE_QUOTE_MODAL_HEADER',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_1 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_1',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_2 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_2',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_3 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_3',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_4 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_4',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_5 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_5',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_6 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_6',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_7 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_7',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_8 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_8',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_9 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_9',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_10 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_10',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_11 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_11',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_12 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_12',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_13 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_13',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_14 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_14',
  CHECKOUT_FINANCE_QUOTE_MODAL_DISCOUNTED_LABEL = 'CHECKOUT_FINANCE_QUOTE_MODAL_DISCOUNTED_LABEL',
  CHECKOUT_FINANCE_INFORMATION_MODAL_TITLE = 'CHECKOUT_FINANCE_INFORMATION_MODAL_TITLE',
  CHECKOUT_FINANCE_INFORMATION_MODAL_DESCRIPTION = 'CHECKOUT_FINANCE_INFORMATION_MODAL_DESCRIPTION',
  CHECKOUT_FINANCE_INFORMATION_MODAL_BACK_BUTTON = 'CHECKOUT_FINANCE_INFORMATION_MODAL_BACK_BUTTON',
  CHECKOUT_FINANCE_INFORMATION_MODAL_CONTINUE_BUTTON = 'CHECKOUT_FINANCE_INFORMATION_MODAL_CONTINUE_BUTTON',
  CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_PERSONAL_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_PERSONAL_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_BUSINESS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_BUSINESS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_PERSONAL_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_PERSONAL_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_BUSINESS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_BUSINESS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_WISR_PERSONAL_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_WISR_PERSONAL_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_WISR_BUSINESS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_WISR_BUSINESS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_TITLE = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_TITLE',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_PERSONALIZED_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_PERSONALIZED_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CHECKOUT_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CHECKOUT_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BUTTON_CHANGE_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BUTTON_CHANGE_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_REQUEST_CALLBACK_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_REQUEST_CALLBACK_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CONTINUE_WITH_FINANCE_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CONTINUE_WITH_FINANCE_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BACK_TO_HOME_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BACK_TO_HOME_LABEL',
  CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_TITLE = 'CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_TITLE',
  CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_CONTENT = 'CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_CONTENT',
  CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_BUTTON_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_HEADER = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_HEADER',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_DESCRIPTION = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_DESCRIPTION',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_NAME_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_NAME_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_EMAIL_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_EMAIL_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_MOBILE_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_MOBILE_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_OTHER_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_OTHER_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CARMA_FINANCE_OPTION = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CARMA_FINANCE_OPTION',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_1 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_1',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_2 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_2',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_3 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_3',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_4 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_4',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_5 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_5',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_6 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_6',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_SUBMIT_BUTTON_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_SUBMIT_BUTTON_LABEL',
  CHECKOUT_BUYER_INFORMATION_HEADER = 'CHECKOUT_BUYER_INFORMATION_HEADER',
  CHECKOUT_BUYER_INFORMATION_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_ADDITION_INFO = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_ADDITION_INFO',
  CHECKOUT_BUYER_INFORMATION_BUTTON_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUTTON_LABEL',
  CHECKOUT_BUYER_INFORMATION_FORM_HEADER = 'CHECKOUT_BUYER_INFORMATION_FORM_HEADER',
  CHECKOUT_BUYER_INFORMATION_INPUT_FIRST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_FIRST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_MIDDLE_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_MIDDLE_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_LAST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_LAST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_DATE_OF_BIRTH_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_DATE_OF_BIRTH_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_STREET_ADDRESS_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_STREET_ADDRESS_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_APARTMENT_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_APARTMENT_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_SUBURB_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_SUBURB_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_POSTCODE_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_POSTCODE_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_STATE_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_STATE_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_HELPER = 'CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_HELPER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_SUB_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_SUB_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUSINESS_NOTIFICATION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUSINESS_NOTIFICATION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SINGLE = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SINGLE',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_MARRIED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_MARRIED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_WIDOWED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_WIDOWED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SEPARATED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SEPARATED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DIVORCED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DIVORCED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DE_FACTO = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DE_FACTO',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_OTHER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_OTHER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_ADDRESS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_ADDRESS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_PREVIOUS_RESIDENTIAL_ADDRESS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_PREVIOUS_RESIDENTIAL_ADDRESS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_1 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_1',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_2 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_2',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_3 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_3',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_4 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_4',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_5 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_5',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TENANCY_AGREEMENT_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TENANCY_AGREEMENT_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_ADDRESS_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_ADDRESS_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_MORTGAGED_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_MORTGAGED_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_MORTGAGE_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_MORTGAGE_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_INVESTMENT_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_INVESTMENT_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_FULL_TIME = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_FULL_TIME',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_PART_TIME = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_PART_TIME',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CASUAL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CASUAL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CONTRACT = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CONTRACT',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_SELF_EMPLOYED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_SELF_EMPLOYED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_RETIRED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_RETIRED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_UNEMPLOYED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_UNEMPLOYED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DISCLAIMER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DISCLAIMER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_1 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_1',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_2 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_2',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_3 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_3',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_OPTION_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_OPTION_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_NO_MORTGAGE_OPTION_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_NO_MORTGAGE_OPTION_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUTTON_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_FOR_BUSINESS = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_FOR_BUSINESS',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_NOTIFICATION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_NOTIFICATION',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_HEADER = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_HEADER',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_BUTTON = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_BUTTON',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_TITLE_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_TITLE_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_FIRST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_FIRST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_MIDDLE_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_MIDDLE_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LAST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LAST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DATE_OF_BIRTH_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DATE_OF_BIRTH_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_ADDRESS_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_ADDRESS_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_STATE_OF_ISSUE_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_STATE_OF_ISSUE_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_NUMBER_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_NUMBER_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_EXPIRY_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_EXPIRY_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LOCATION_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LOCATION_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUSINESS_INFORMATION_HEADER = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUSINESS_INFORMATION_HEADER',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_INFORMATION_HEADER = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_INFORMATION_HEADER',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUTTON = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUTTON',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_COMPANY_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_COMPANY_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_ABN_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_ABN_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_TITLE_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_TITLE_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_FIRST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_FIRST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_MIDDLE_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_MIDDLE_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LAST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LAST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DATE_OF_BIRTH_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DATE_OF_BIRTH_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_ADDRESS_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_ADDRESS_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_STATE_OF_ISSUE_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_STATE_OF_ISSUE_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_EXPIRY_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_EXPIRY_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_DESCRIPTION',
  CHECKOUT_DELIVERY_OPTION_FORM_DESCRIPTION = 'CHECKOUT_DELIVERY_OPTION_FORM_DESCRIPTION',
  CHECKOUT_DELIVERY_OPTION_FORM_CTA = 'CHECKOUT_DELIVERY_OPTION_FORM_CTA',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDRESS_TITLE = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDRESS_TITLE',
  CHECKOUT_DELIVERY_OPTION_FORM_DELIVERY_LABEL = 'CHECKOUT_DELIVERY_OPTION_FORM_DELIVERY_LABEL',
  CHECKOUT_DELIVERY_OPTION_FORM_COLLECTION_LABEL = 'CHECKOUT_DELIVERY_OPTION_FORM_COLLECTION_LABEL',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_TITLE = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_TITLE',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_TITLE = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_TITLE',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_DESCRIPTION = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_DESCRIPTION',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_DESCRIPTION = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_DESCRIPTION',
  CHECKOUT_DELIVERY_LOADING_MESSAGE = 'CHECKOUT_DELIVERY_LOADING_MESSAGE',
  CHECKOUT_DELIVERY_OPTION_FORM_HELP = 'CHECKOUT_DELIVERY_OPTION_FORM_HELP',
  CHECKOUT_DELIVERY_OPTION_FORM_DELIVERABLE = 'CHECKOUT_DELIVERY_OPTION_FORM_DELIVERABLE',
  CHECKOUT_DELIVERY_OPTION_FORM_NOT_DELIVERABLE = 'CHECKOUT_DELIVERY_OPTION_FORM_NOT_DELIVERABLE',
  CHECKOUT_DELIVERY_OPTION_DELIVERY_ADDITIONAL_INFORMATION = 'CHECKOUT_DELIVERY_OPTION_DELIVERY_ADDITIONAL_INFORMATION',
  CHECKOUT_DELIVERY_OPTION_COLLECTION_ADDITIONAL_INFORMATION = 'CHECKOUT_DELIVERY_OPTION_COLLECTION_ADDITIONAL_INFORMATION',
  CHECKOUT_DELIVERY_OPTION_CARMA_COLLECT = 'CHECKOUT_DELIVERY_OPTION_CARMA_COLLECT',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_CONTACT_US = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_CONTACT_US',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_SEE_EXAMPLE = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_SEE_EXAMPLE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_DUE_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_DUE_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_3 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_3',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_4 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_4',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_COLLECT_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_COLLECT_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_YES_TRADE_IN = 'CHECKOUT_PAYMENT_SUMMARY_FORM_YES_TRADE_IN',
  CHECKOUT_PAYMENT_SUMMARY_FORM_NO_TRADE_IN = 'CHECKOUT_PAYMENT_SUMMARY_FORM_NO_TRADE_IN ',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_VALUE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_VALUE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_VALUE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_VALUE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_REPAYMENT = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_REPAYMENT',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_VALUE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_VALUE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_3 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_3',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_4 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_4',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_5 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_5',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_3 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_3',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_COULD_NOT_DECIDE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_COULD_NOT_DECIDE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_SWITCH_LABEL = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_SWITCH_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_DEPOSIT = 'CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_DEPOSIT',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_PRICE_LABEL = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_PRICE_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_TRANSACTION_FEE_TITLE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_TRANSACTION_FEE_TITLE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_RESERVATION = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_RESERVATION',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_PAY_FULL_AMOUNT = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_PAY_FULL_AMOUNT',
  CHECKOUT_PAYMENT_SUMMARY_FORM_EDIT_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_EDIT_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_MOBILE_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_MOBILE_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_VIEW_FULL_SUMMARY_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_VIEW_FULL_SUMMARY_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_SUBMIT_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_SUBMIT_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYING_WITHOUT_FINANCE_FULL_AMOUNT_SUBMIT_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYING_WITHOUT_FINANCE_FULL_AMOUNT_SUBMIT_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_SUBMIT_LABEL = 'CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_SUBMIT_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_INSTANT_FINANCE_QUOTE_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_INSTANT_FINANCE_QUOTE_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FINANCE_QUOTES_NOTE = 'CHECKOUT_PAYMENT_SUMMARY_FINANCE_QUOTES_NOTE',
  CHECKOUT_DOC_MANAGEMENT_HEADER = 'CHECKOUT_DOC_MANAGEMENT_HEADER',
  CHECKOUT_DOC_MANAGEMENT_DESCRIPTION = 'CHECKOUT_DOC_MANAGEMENT_DESCRIPTION',
  CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_HEADER = 'CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_HEADER',
  CHECKOUT_DOC_MANAGEMENT_FORM_5_HEADER = 'CHECKOUT_DOC_MANAGEMENT_FORM_5_HEADER',
  CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_CONTENT = 'CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_CONTENT',
  CHECKOUT_DOC_MANAGEMENT_FORM_5_CONTENT = 'CHECKOUT_DOC_MANAGEMENT_FORM_5_CONTENT',
  CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_CONTENT = 'CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_CONTENT',
  CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_BUTTON_LABEL = 'CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_SUBMIT_QUOTE_LABEL = 'CHECKOUT_QUOTE_CARD_SUBMIT_QUOTE_LABEL',
  CHECKOUT_QUOTE_CARD_QUOTE_DETAILS_TITLE = 'CHECKOUT_QUOTE_CARD_QUOTE_DETAILS_TITLE',
  CHECKOUT_QUOTE_CARD_REPAYMENT_AMOUNT_TITLE = 'CHECKOUT_QUOTE_CARD_REPAYMENT_AMOUNT_TITLE',
  CHECKOUT_QUOTE_CARD_LOAN_AMOUNT_LABEL = 'CHECKOUT_QUOTE_CARD_LOAN_AMOUNT_LABEL',
  CHECKOUT_QUOTE_CARD_DEPOSIT_LABEL = 'CHECKOUT_QUOTE_CARD_DEPOSIT_LABEL',
  CHECKOUT_QUOTE_CARD_TERM_LABEL = 'CHECKOUT_QUOTE_CARD_TERM_LABEL',
  CHECKOUT_QUOTE_CARD_WEEKLY_LABEL = 'CHECKOUT_QUOTE_CARD_WEEKLY_LABEL',
  CHECKOUT_QUOTE_CARD_INTEREST_RATE_LABEL = 'CHECKOUT_QUOTE_CARD_INTEREST_RATE_LABEL',
  CHECKOUT_QUOTE_CARD_FEE_BREAKDOWN_LABEL = 'CHECKOUT_QUOTE_CARD_FEE_BREAKDOWN_LABEL',
  CHECKOUT_QUOTE_CARD_CHECKOUT_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_CHECKOUT_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_BUILD_YEAR_TITLE = 'CHECKOUT_QUOTE_CARD_BUILD_YEAR_TITLE',
  CHECKOUT_QUOTE_CARD_BUILD_YEAR_TEXT = 'CHECKOUT_QUOTE_CARD_BUILD_YEAR_TEXT',
  CHECKOUT_QUOTE_CARD_GET_PRE_APPROVED_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_GET_PRE_APPROVED_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_BROWSE_CARS_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_BROWSE_CARS_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_DISCOUNT_OFFER_LABEL = 'CHECKOUT_QUOTE_CARD_DISCOUNT_OFFER_LABEL',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_CONFIRMATION_LABEL = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_CONFIRMATION_LABEL',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_TITLE = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_TITLE',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_DESCRIPTION = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_DESCRIPTION',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_CTA_LABEL = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_CTA_LABEL',
  CHECKOUT_VIEW_PURCHASE_SUMMARY_BUTTON_LABEL = 'CHECKOUT_VIEW_PURCHASE_SUMMARY_BUTTON_LABEL',
  CHECKOUT_GET_STARTED_HEADER = 'CHECKOUT_GET_STARTED_HEADER',
  CHECKOUT_GET_STARTED_DESCRIPTION = 'CHECKOUT_GET_STARTED_DESCRIPTION',
  CHECKOUT_GET_STARTED_OPTION_1_TITLE = 'CHECKOUT_GET_STARTED_OPTION_1_TITLE',
  CHECKOUT_GET_STARTED_OPTION_1_DESCRIPTION = 'CHECKOUT_GET_STARTED_OPTION_1_DESCRIPTION',
  CHECKOUT_GET_STARTED_OPTION_2_TITLE = 'CHECKOUT_GET_STARTED_OPTION_2_TITLE',
  CHECKOUT_GET_STARTED_OPTION_2_DESCRIPTION = 'CHECKOUT_GET_STARTED_OPTION_2_DESCRIPTION',
  CHECKOUT_GET_STARTED_INCLUDED_TEXT = 'CHECKOUT_GET_STARTED_INCLUDED_TEXT',
  CHECKOUT_GET_STARTED_FREE_TEXT = 'CHECKOUT_GET_STARTED_FREE_TEXT',
  CHECKOUT_GET_STARTED_GUARANTEE_TEXT = 'CHECKOUT_GET_STARTED_GUARANTEE_TEXT',
  CHECKOUT_GET_STARTED_WARRANTY_TEXT = 'CHECKOUT_GET_STARTED_WARRANTY_TEXT',
  CHECKOUT_GET_STARTED_NRMA_TEXT = 'CHECKOUT_GET_STARTED_NRMA_TEXT',
  CHECKOUT_GET_STARTED_DELIVERY_TEXT = 'CHECKOUT_GET_STARTED_DELIVERY_TEXT',
  CHECKOUT_GET_STARTED_DELIVERY_LINK = 'CHECKOUT_GET_STARTED_DELIVERY_LINK',
  CHECKOUT_GET_STARTED_SUBMIT_BUTTON_LABEL = 'CHECKOUT_GET_STARTED_SUBMIT_BUTTON_LABEL',
  CHECKOUT_GET_STARTED_HOLD_CAR_TEXT = 'CHECKOUT_GET_STARTED_HOLD_CAR_TEXT',
  CHECKOUT_GET_STARTED_HOLD_CAR_TIME = 'CHECKOUT_GET_STARTED_HOLD_CAR_TIME',
  CHECKOUT_GET_STARTED_VIEW_TERMS = 'CHECKOUT_GET_STARTED_VIEW_TERMS',
  CHECKOUT_GET_STARTED_TERM_MODAL_TITLE = 'CHECKOUT_GET_STARTED_TERM_MODAL_TITLE',
  CHECKOUT_GET_STARTED_TERM_MODAL_CONTENT = 'CHECKOUT_GET_STARTED_TERM_MODAL_CONTENT',
  CHECKOUT_GET_STARTED_CHAT_WIDGET_LABEL = 'CHECKOUT_GET_STARTED_CHAT_WIDGET_LABEL',
  CHECKOUT_CHANGE_PAYMENT_TITLE = 'CHECKOUT_CHANGE_PAYMENT_TITLE',
  CHECKOUT_CHANGE_PAYMENT_DESCRIPTION = 'CHECKOUT_CHANGE_PAYMENT_DESCRIPTION',
  CHECKOUT_CHANGE_PAYMENT_BACK_BUTTON_LABEL = 'CHECKOUT_CHANGE_PAYMENT_BACK_BUTTON_LABEL',
  CHECKOUT_CHANGE_PAYMENT_CONTINUE_BUTTON_LABEL = 'CHECKOUT_CHANGE_PAYMENT_CONTINUE_BUTTON_LABEL',
  CHECKOUT_CHANGE_PAYMENT_OPTION_LABEL = 'CHECKOUT_CHANGE_PAYMENT_OPTION_LABEL',
  MAGIC_TERM_PRIVACY = 'MAGIC_TERM_PRIVACY',
  MAGIC_TERM_LOADING_TEXT = 'MAGIC_TERM_LOADING_TEXT',

  //
  //
  // Product list microcopy (PLP)
  //
  //
  PLP_PAGE_TITLE = 'PLP_PAGE_TITLE',
  PLP_PAGE_DESCRIPTION = 'PLP_PAGE_DESCRIPTION',
  PLP_PILL_PURCHASE_PROGRESS = 'PLP_PILL_PURCHASE_PROGRESS',
  PLP_PILL_MY_PURCHASE = 'PLP_PILL_MY_PURCHASE',
  PLP_PILL_DRAFT_ORDER = 'PLP_PILL_DRAFT_ORDER',
  PLP_PILL_RESERVED = 'PLP_PILL_RESERVED',
  PLP_PRODUCT_CARD_ESTIMATE_REPAYMENT = 'PLP_PRODUCT_CARD_ESTIMATE_REPAYMENT',
  PLP_PILL_REFINE_PAYMENT_HEADLINE = 'PLP_PILL_REFINE_PAYMENT_HEADLINE',
  PLP_PILL_REFINE_PAYMENT_DESCRIPTION = 'PLP_PILL_REFINE_PAYMENT_DESCRIPTION',
  //
  //
  // Header menu microcopy
  //
  //
  GENERAL_HEADER_PURCHASE_PROGRESS_BUTTON = 'GENERAL_HEADER_PURCHASE_PROGRESS_BUTTON',
  GENERAL_HEADER_TIME_PURCHASE_UNAVAILABLE = 'GENERAL_HEADER_TIME_PURCHASE_UNAVAILABLE',
  GENERAL_HEADER_TIME_REMAINING = 'GENERAL_HEADER_TIME_REMAINING',
  GENERAL_HEADER_BUTTON_SIGN_OUT = 'GENERAL_HEADER_BUTTON_SIGN_OUT',
  GENERAL_HEADER_SEARCH_PLACEHOLDER = 'GENERAL_HEADER_SEARCH_PLACEHOLDER',
  GENERAL_HEADER_SEARCH_PLACEHOLDER_MOBILE = 'GENERAL_HEADER_SEARCH_PLACEHOLDER_MOBILE',
  GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_ALL_CTA = 'GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_ALL_CTA',
  GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_RESULT_CTA = 'GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_RESULT_CTA',
  GENERAL_HEADER_SEARCH_DROPDOWN_CAR_SUGGESTIONS_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_CAR_SUGGESTIONS_LABEL',
  GENERAL_HEADER_SEARCH_DROPDOWN_CAR_JUST_ADDED_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_CAR_JUST_ADDED_LABEL',
  GENERAL_HEADER_SEARCH_DROPDOWN_FILTER_SUGGESTIONS_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_FILTER_SUGGESTIONS_LABEL',
  GENERAL_HEADER_SEARCH_DROPDOWN_RECENT_SEARCH_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_RECENT_SEARCH_LABEL',
  GENERAL_HEADER_LOGIN_BUTTON_LABEL = 'GENERAL_HEADER_LOGIN_BUTTON_LABEL',
  //
  //
  // Account microcopy
  //
  //
  ACCOUNT_NAV_GREETING = 'ACCOUNT_NAV_GREETING',
  ACCOUNT_NAV_MY_PURCHASES = 'ACCOUNT_NAV_MY_PURCHASES',
  ACCOUNT_NAV_PERSONAL_DETAILS = 'ACCOUNT_NAV_PERSONAL_DETAILS',
  ACCOUNT_NAV_SIGN_OUT = 'ACCOUNT_NAV_SIGN_OUT',
  ACCOUNT_NAV_MY_FINANCE_QUOTES = 'ACCOUNT_NAV_MY_FINANCE_QUOTES',
  ACCOUNT_PURCHASES_TITLE = 'ACCOUNT_PURCHASES_TITLE',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_SCHEDULED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_SCHEDULED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_PREPARING_VEHICLE = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_PREPARING_VEHICLE',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_OUT_FOR_DELIVERY = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_OUT_FOR_DELIVERY',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_DELIVERED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_DELIVERED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURN_REQUESTED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURN_REQUESTED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURNED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURNED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_CANCELLED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_CANCELLED',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_SCHEDULED = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_SCHEDULED',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_PREPARING_VEHICLE = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_PREPARING_VEHICLE',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_OUT_FOR_COLLECTION = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_OUT_FOR_COLLECTION',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_COLLECTED = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_COLLECTED',
  ACCOUNT_PURCHASES_NO_CARS_PURCHASED = 'ACCOUNT_PURCHASES_NO_CARS_PURCHASED',
  ACCOUNT_PURCHASES_CAR_DETAILS = 'ACCOUNT_PURCHASES_CAR_DETAILS',
  ACCOUNT_PURCHASES_CARMA_CHECKLIST = 'ACCOUNT_PURCHASES_CARMA_CHECKLIST',
  ACCOUNT_PURCHASES_VIEW_PURCHASE_INFO = 'ACCOUNT_PURCHASES_VIEW_PURCHASE_INFO',
  ACCOUNT_FINANCE_TITLE = 'ACCOUNT_FINANCE_TITLE',
  ACCOUNT_NOTIFICATION_TITLE = 'ACCOUNT_NOTIFICATION_TITLE',
  ACCOUNT_NOTIFICATION_SETTINGS = 'ACCOUNT_NOTIFICATION_SETTINGS',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE_GUARANTEE = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE_GUARANTEE',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE_T_C = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE_T_C',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE_PRIVACY_POLICY = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE_PRIVACY_POLICY',
  ACCOUNT_PURCHASES_MY_PAYMENT_PROGRESS = 'ACCOUNT_PURCHASES_MY_PAYMENT_PROGRESS',
  ACCOUNT_PURCHASES_PAYING_YOUR_REMAINING_FUNDS = 'ACCOUNT_PURCHASES_PAYING_YOUR_REMAINING_FUNDS',
  ACCOUNT_PURCHASES_VIEW_BANK_TRANSFER_DETAILS_CTA = 'ACCOUNT_PURCHASES_VIEW_BANK_TRANSFER_DETAILS_CTA',
  ACCOUNT_PURCHASES_RESERVATION_DEPOSIT = 'ACCOUNT_PURCHASES_RESERVATION_DEPOSIT',
  ACCOUNT_PURCHASES_FINANCE_DEPOSIT = 'ACCOUNT_PURCHASES_FINANCE_DEPOSIT',
  ACCOUNT_PURCHASES_FINANCE = 'ACCOUNT_PURCHASES_FINANCE',
  ACCOUNT_PURCHASES_REMAINING_AMOUNT = 'ACCOUNT_PURCHASES_REMAINING_AMOUNT',
  ACCOUNT_PURCHASES_TRADE_IN = 'ACCOUNT_PURCHASES_TRADE_IN',
  ACCOUNT_PURCHASES_FINANCE_PAYOUT = 'ACCOUNT_PURCHASES_FINANCE_PAYOUT',
  ACCOUNT_PURCHASES_DEPOSIT_PAID_ON = 'ACCOUNT_PURCHASES_DEPOSIT_PAID_ON',
  ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT = 'ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT',
  ACCOUNT_PURCHASES_REMAINING_FUNDS = 'ACCOUNT_PURCHASES_REMAINING_FUNDS',
  ACCOUNT_PURCHASES_ORDER_COMPLETED = 'ACCOUNT_PURCHASES_ORDER_COMPLETED',
  ACCOUNT_PURCHASES_PAY_REMAINING_FUNDS = 'ACCOUNT_PURCHASES_PAY_REMAINING_FUNDS',
  ACCOUNT_PURCHASES_MY_DOCUMENTS = 'ACCOUNT_PURCHASES_MY_DOCUMENTS',
  ACCOUNT_PURCHASES_MY_DOCUMENTS_T_C = 'ACCOUNT_PURCHASES_MY_DOCUMENTS_T_C',
  ACCOUNT_PURCHASES_MY_DOCUMENTS_PRIVACY_POLICY = 'ACCOUNT_PURCHASES_MY_DOCUMENTS_PRIVACY_POLICY',
  ACCOUNT_PURCHASES_MY_DELIVERY = 'ACCOUNT_PURCHASES_MY_DELIVERY',
  ACCOUNT_PURCHASES_MY_COLLECTION = 'ACCOUNT_PURCHASES_MY_COLLECTION',
  ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_SCHEDULED = 'ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_SCHEDULED',
  ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_DELIVERED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_DELIVERED',
  ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_SCHEDULED = 'ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_SCHEDULED',
  ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_MY_COLLECTION_IMAGE_DESCRIPTION_COLLECTED = 'ACCOUNT_PURCHASES_MY_COLLECTION_IMAGE_DESCRIPTION_COLLECTED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURN_REQUESTED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURN_REQUESTED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURNED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURNED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_CANCELLED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_CANCELLED',
  ACCOUNT_PURCHASES_MY_DELIVERY_TIME = 'ACCOUNT_PURCHASES_MY_DELIVERY_TIME',
  ACCOUNT_PURCHASES_MY_COLLECTION_TIME = 'ACCOUNT_PURCHASES_MY_COLLECTION_TIME',
  ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION = 'ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION',
  ACCOUNT_PURCHASES_MY_COLLECTION_LOCATION = 'ACCOUNT_PURCHASES_MY_COLLECTION_LOCATION',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_TITLE = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_TITLE',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_DESCRIPTION = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_DESCRIPTION',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NAME = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NAME',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_BSB = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_BSB',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NUMBER = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NUMBER',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_REFERENCE_NUMBER = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_REFERENCE_NUMBER',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_PAY_ID = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_PAY_ID',
  ACCOUNT_PURCHASES_MY_DELIVERY_NOT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_MY_DELIVERY_NOT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_MY_DELIVERY_ABLE_SCHEDULE_CONTENT = 'ACCOUNT_PURCHASES_MY_DELIVERY_ABLE_SCHEDULE_CONTENT',
  ACCOUNT_PURCHASES_MY_DELIVERY_UNABLE_SCHEDULE_CONTENT = 'ACCOUNT_PURCHASES_MY_DELIVERY_UNABLE_SCHEDULE_CONTENT',
  ACCOUNT_PURCHASES_MY_DELIVERY_CAR_DELIVERED_MESSAGE = 'ACCOUNT_PURCHASES_MY_DELIVERY_CAR_DELIVERED_MESSAGE',
  ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION_BUTTON_LABEL = 'ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION_BUTTON_LABEL',
  ACCOUNT_PURCHASES_MY_DELIVERY_TIME_BUTTON_LABEL = 'ACCOUNT_PURCHASES_MY_DELIVERY_TIME_BUTTON_LABEL',
  ACCOUNT_PURCHASES_DELIVERY_HEADER = 'ACCOUNT_PURCHASES_DELIVERY_HEADER',
  ACCOUNT_PURCHASES_DELIVERY_NOT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_DELIVERY_NOT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_DELIVERY_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_DELIVERY_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_DELIVERY_DELIVERED_CONTENT = 'ACCOUNT_PURCHASES_DELIVERY_DELIVERED_CONTENT',
  ACCOUNT_PURCHASES_DELIVERY_LOCATION_TITLE = 'ACCOUNT_PURCHASES_DELIVERY_LOCATION_TITLE',
  ACCOUNT_PURCHASES_DELIVERY_DATE_TIME_TITLE = 'ACCOUNT_PURCHASES_DELIVERY_DATE_TIME_TITLE',
  ACCOUNT_PURCHASES_COLLECT_HEADER = 'ACCOUNT_PURCHASES_COLLECT_HEADER',
  ACCOUNT_PURCHASES_COLLECT_NOT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_COLLECT_NOT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_COLLECT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_COLLECT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_COLLECT_DELIVERED_CONTENT = 'ACCOUNT_PURCHASES_COLLECT_DELIVERED_CONTENT',
  ACCOUNT_PURCHASES_COLLECT_LOCATION_TITLE = 'ACCOUNT_PURCHASES_COLLECT_LOCATION_TITLE',
  ACCOUNT_PURCHASES_COLLECT_DATE_TIME_TITLE = 'ACCOUNT_PURCHASES_COLLECT_DATE_TIME_TITLE',
  ACCOUNT_PURCHASES_GET_DIRECTIONS_BUTTON_LABEL = 'ACCOUNT_PURCHASES_GET_DIRECTIONS_BUTTON_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_EXCLUDE_GOVERNMENT_CHARGES_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_EXCLUDE_GOVERNMENT_CHARGES_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_PROCESSING_FEE_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_PROCESSING_FEE_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_TBD_BY_PAYMENT_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_TBD_BY_PAYMENT_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_PAYMENT_METHOD_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_PAYMENT_METHOD_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_BILLING_ADDRESS_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_BILLING_ADDRESS_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_FUNDS_DUE_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_FUNDS_DUE_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_TOTAL_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_TOTAL_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_BACK_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_BACK_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_PAY_BALANCE_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_PAY_BALANCE_LABEL',
  ACCOUNT_PERSONAL_DETAILS_TITLE = 'ACCOUNT_PERSONAL_DETAILS_TITLE',
  ACCOUNT_PERSONAL_INFO_TITLE = 'ACCOUNT_PERSONAL_INFO_TITLE',
  ACCOUNT_PERSONAL_INFO_NAME = 'ACCOUNT_PERSONAL_INFO_NAME',
  ACCOUNT_PERSONAL_INFO_EMAIL = 'ACCOUNT_PERSONAL_INFO_EMAIL',
  ACCOUNT_PERSONAL_INFO_PHONE = 'ACCOUNT_PERSONAL_INFO_PHONE',
  ACCOUNT_PERSONAL_INFO_PASSWORD = 'ACCOUNT_PERSONAL_INFO_PASSWORD',
  ACCOUNT_PERSONAL_INFO_ADD_PHONE = 'ACCOUNT_PERSONAL_INFO_ADD_PHONE',
  ACCOUNT_PERSONAL_INFO_ADD_PASSWORD = 'ACCOUNT_PERSONAL_INFO_ADD_PASSWORD',
  ACCOUNT_PERSONAL_INFO_ADD_PASSWORD_DESCRIPTION = 'ACCOUNT_PERSONAL_INFO_ADD_PASSWORD_DESCRIPTION',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_SUCCESS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_SUCCESS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION_SUCCESS = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION_SUCCESS',
  ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_SUCCESS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_SUCCESS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_DESCRIPTION_SUCCESS = 'ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_DESCRIPTION_SUCCESS',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_SAVE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_SAVE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CANCEL_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CANCEL_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CLOSE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CLOSE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_SUCCESS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_SUCCESS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_DESCRIPTION_SUCCESS = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_DESCRIPTION_SUCCESS',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_FIRST_NAME_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_FIRST_NAME_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_LAST_NAME_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_LAST_NAME_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_EMAIL_ADDRESS_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_EMAIL_ADDRESS_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_SAVE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_SAVE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CANCEL_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CANCEL_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CLOSE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CLOSE_BUTTON_LABEL',
  // ACCOUNT_PERSONAL_INFO_EDIT_PHONE_SUCCESS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_SUCCESS_TITLE',
  // ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION_SUCCESS = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION_SUCCESS',
  // ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_LABEL',
  // ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION',
  // ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_SAVE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_SAVE_BUTTON_LABEL',
  // ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CANCEL_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CANCEL_BUTTON_LABEL',
  // ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CLOSE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CLOSE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD = 'ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD',
  ACCOUNT_UPDATE_PERSONAL_INFO_SAVE_BUTTON_LABEL = 'ACCOUNT_UPDATE_PERSONAL_INFO_SAVE_BUTTON_LABEL',
  ACCOUNT_UPDATE_PERSONAL_INFO_CANCEL_BUTTON_LABEL = 'ACCOUNT_UPDATE_PERSONAL_INFO_CANCEL_BUTTON_LABEL',
  ACCOUNT_UPDATE_PERSONAL_INFO_CLOSE_BUTTON_LABEL = 'ACCOUNT_UPDATE_PERSONAL_INFO_CLOSE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS = 'ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS',
  ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS_OPTION = 'ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS_OPTION',
  ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_TITLE = 'ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_TITLE',
  ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_EMPTY = 'ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_EMPTY',
  ACCOUNT_PAYMENT_TITLE = 'ACCOUNT_PAYMENT_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_TITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_SUBTITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_SUBTITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_DESCRIPTION = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_DESCRIPTION',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_INFO_TITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_INFO_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_NAME = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_NAME',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_BSB = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_BSB',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_REF = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_REF',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_IMPORTANT_INFO = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_IMPORTANT_INFO',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_TITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_INFO = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_INFO',
  ACCOUNT_PAYMENT_OPTION_CARD_TITLE = 'ACCOUNT_PAYMENT_OPTION_CARD_TITLE',
  ACCOUNT_PAYMENT_OPTION_CARD_SUBTITLE = 'ACCOUNT_PAYMENT_OPTION_CARD_SUBTITLE',
  ACCOUNT_PAYMENT_OPTION_CARD_SECURITY = 'ACCOUNT_PAYMENT_OPTION_CARD_SECURITY',
  ACCOUNT_PAYMENT_ERROR_NO_PAYMENT_METHOD = 'ACCOUNT_PAYMENT_ERROR_NO_PAYMENT_METHOD',
  ACCOUNT_PAYMENT_ERROR_CARD_INVALID = 'ACCOUNT_PAYMENT_ERROR_CARD_INVALID',
  ACCOUNT_PAYMENT_CARD_INVALID_SHOW_CONTACT_TEXT = 'ACCOUNT_PAYMENT_CARD_INVALID_SHOW_CONTACT_TEXT',
  GENERAL_PAYMENT_ERROR = 'GENERAL_PAYMENT_ERROR',
  ACCOUNT_PAYMENT_FUNDS_REMAINING = 'ACCOUNT_PAYMENT_FUNDS_REMAINING',
  ACCOUNT_PAYMENT_PROCESSING_FEE = 'ACCOUNT_PAYMENT_PROCESSING_FEE',
  ACCOUNT_PAYMENT_TOTAL = 'ACCOUNT_PAYMENT_TOTAL',
  ACCOUNT_PAYMENT_PROCESSING_FEE_DEFAULT = 'ACCOUNT_PAYMENT_PROCESSING_FEE_DEFAULT',
  ACCOUNT_PAYMENT_PROCESSING_FEE_FREE = 'ACCOUNT_PAYMENT_PROCESSING_FEE_FREE',
  ACCOUNT_PAYMENT_NEXT_CARD = 'ACCOUNT_PAYMENT_NEXT_CARD',
  ACCOUNT_PAYMENT_NEXT_BANK_TRANSFER = 'ACCOUNT_PAYMENT_NEXT_BANK_TRANSFER',
  ACCOUNT_PAYMENT_PREV = 'ACCOUNT_PAYMENT_PREV',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_TITLE = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_TITLE',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_OK = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_OK',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CANCEL = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CANCEL',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CONTENT = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CONTENT',
  ACCOUNT_PAYMENT_BANK_TRANSFER_COPY = 'ACCOUNT_PAYMENT_BANK_TRANSFER_COPY',
  ACCOUNT_NOTIFICATIONS_WATCHLIST_TITLE = 'ACCOUNT_NOTIFICATIONS_WATCHLIST_TITLE',
  ACCOUNT_NOTIFICATIONS_DISABLE_ALL_BUTTON = 'ACCOUNT_NOTIFICATIONS_DISABLE_ALL_BUTTON',
  ACCOUNT_NOTIFICATIONS_PRICE_DROP = 'ACCOUNT_NOTIFICATIONS_PRICE_DROP',
  ACCOUNT_NOTIFICATIONS_VEHICLE_AVAILABLE = 'ACCOUNT_NOTIFICATIONS_VEHICLE_AVAILABLE',
  ACCOUNT_NOTIFICATIONS_PRICE_DROP_TITLE = 'ACCOUNT_NOTIFICATIONS_PRICE_DROP_TITLE',
  ACCOUNT_NOTIFICATIONS_PRICE_DROP_SUBTITLE = 'ACCOUNT_NOTIFICATIONS_PRICE_DROP_SUBTITLE',
  ACCOUNT_NOTIFICATIONS_WATCHLIST_WATCHERS_INCREASE = 'ACCOUNT_NOTIFICATIONS_WATCHLIST_WATCHERS_INCREASE',
  ACCOUNT_PAYID_HEADER = 'ACCOUNT_PAYID_HEADER',
  ACCOUNT_PAYID_TITLE = 'ACCOUNT_PAYID_TITLE',
  ACCOUNT_PAYID_DEBIT_CREDIT_CARD_TITLE = 'ACCOUNT_PAYID_DEBIT_CREDIT_CARD_TITLE',
  ACCOUNT_PAYID_BANK_TRANSFER_TITLE = 'ACCOUNT_PAYID_BANK_TRANSFER_TITLE',
  ACCOUNT_PAYID_BUTTON_LABEL = 'ACCOUNT_PAYID_BUTTON_LABEL',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAY_ID_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAY_ID_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_DEBIT_CREDIT_CARD_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_DEBIT_CREDIT_CARD_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_ONLINE_BANK_TRANSFER_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_ONLINE_BANK_TRANSFER_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_REMAINING_AMOUNT_TITLE = 'ACCOUNT_PAYMENT_OPTIONS_FORM_REMAINING_AMOUNT_TITLE',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PROCESS_FEE_TITLE = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PROCESS_FEE_TITLE',
  ACCOUNT_PAYMENT_OPTIONS_FORM_TOTAL_TITLE = 'ACCOUNT_PAYMENT_OPTIONS_FORM_TOTAL_TITLE',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DETAILS_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DETAILS_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DESCRIPTION = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DESCRIPTION',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_COPY_TEXT = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_COPY_TEXT',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_LEFT_TEXT = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_LEFT_TEXT',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_1 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_1',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_2 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_2',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_3 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_3',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_4 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_4',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_INFO = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_INFO',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_BY = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_BY',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_DESCRIPTION = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_DESCRIPTION',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAID_BUTTON_LABEL = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAID_BUTTON_LABEL',
  ACCOUNT_PAYMENT_OPTIONS_FORM_SUBMIT_BUTTON_LABEL = 'ACCOUNT_PAYMENT_OPTIONS_FORM_SUBMIT_BUTTON_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_1_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_1_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_2_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_2_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_3_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_3_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_4_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_4_LABEL',
  MY_ACCOUNT_PURCHASES_PAYMENT_RECEIVED = 'MY_ACCOUNT_PURCHASES_PAYMENT_RECEIVED',
  MY_ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT = 'MY_ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT',
  MY_ACCOUNT_PURCHASES_RESERVATION_DEPOSIT = 'MY_ACCOUNT_PURCHASES_RESERVATION_DEPOSIT',
  MY_ACCOUNT_PURCHASE_EMPTY_ACCOUNT = 'MY_ACCOUNT_PURCHASE_EMPTY_ACCOUNT',
  //
  //
  // Authentication Workflow microcopy
  //
  //
  AUTHENTICATION_SIGNUP_TITLE = 'AUTHENTICATION_SIGNUP_TITLE',
  AUTHENTICATION_SIGNUP_CAPTION = 'AUTHENTICATION_SIGNUP_CAPTION',
  AUTHENTICATION_SIGNUP_WATCHLIST_TITLE = 'AUTHENTICATION_SIGNUP_WATCHLIST_TITLE',
  AUTHENTICATION_SIGNUP_WATCHLIST_CAPTION = 'AUTHENTICATION_SIGNUP_WATCHLIST_CAPTION',
  AUTHENTICATION_SIGNUP_CAR_ALERTS_TITLE = 'AUTHENTICATION_SIGNUP_CAR_ALERTS_TITLE',
  AUTHENTICATION_SIGNUP_CAR_ALERTS_CAPTION = 'AUTHENTICATION_SIGNUP_CAR_ALERTS_CAPTION',
  AUTHENTICATION_SIGNUP_CHECKOUT_TITLE = 'AUTHENTICATION_SIGNUP_CHECKOUT_TITLE',
  AUTHENTICATION_SIGNUP_CHECKOUT_CAPTION = 'AUTHENTICATION_SIGNUP_CHECKOUT_CAPTION',
  AUTHENTICATION_SIGNUP_CARMATCH_TITLE = 'AUTHENTICATION_SIGNUP_CARMATCH_TITLE',
  AUTHENTICATION_SIGNUP_CARMATCH_CAPTION = 'AUTHENTICATION_SIGNUP_CARMATCH_CAPTION',
  AUTHENTICATION_SIGNUP_CHECKOUT_SHORT_CAPTION = 'AUTHENTICATION_SIGNUP_CHECKOUT_SHORT_CAPTION',
  AUTHENTICATION_SIGNUP_LOGIN_FACEBOOK_LABEL = 'AUTHENTICATION_SIGNUP_LOGIN_FACEBOOK_LABEL',
  AUTHENTICATION_SIGNUP_LOGIN_GOOGLE_LABEL = 'AUTHENTICATION_SIGNUP_LOGIN_GOOGLE_LABEL',
  AUTHENTICATION_SIGNUP_CAR_ALERTS_DESCRIPTION = 'AUTHENTICATION_SIGNUP_CAR_ALERTS_DESCRIPTION',
  AUTHENTICATION_RESEND_PASSWORD_FORM_TITLE = 'AUTHENTICATION_RESEND_PASSWORD_FORM_TITLE',
  AUTHENTICATION_RESEND_PASSWORD_FORM_CAPTION = 'AUTHENTICATION_RESEND_PASSWORD_FORM_CAPTION',
  AUTHENTICATION_RESET_PASSWORD_FORM_TITLE = 'AUTHENTICATION_RESET_PASSWORD_FORM_TITLE',
  AUTHENTICATION_RESET_PASSWORD_FORM_CAPTION = 'AUTHENTICATION_RESET_PASSWORD_FORM_CAPTION',
  AUTHENTICATION_NEW_PASSWORD_FORM_TITLE = 'AUTHENTICATION_NEW_PASSWORD_FORM_TITLE',
  AUTHENTICATION_SIGNIN_FORM_TITLE = 'AUTHENTICATION_SIGNIN_FORM_TITLE',
  AUTHENTICATION_SIGNIN_FORM_CAPTION = 'AUTHENTICATION_SIGNIN_FORM_CAPTION',
  AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_TITLE = 'AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_TITLE',
  AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_CAPTION = 'AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_CAPTION',
  AUTHENTICATION_VERIFICATION_FORM_TITLE = 'AUTHENTICATION_VERIFICATION_FORM_TITLE',
  AUTHENTICATION_VERIFICATION_FORM_CAPTION = 'AUTHENTICATION_VERIFICATION_FORM_CAPTION',
  AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_1 = 'AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_1',
  AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_2 = 'AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_2',
  AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_3 = 'AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_3',
  AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_1 = 'AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_1',
  AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_2 = 'AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_2',
  AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_3 = 'AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_3',
  AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_1 = 'AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_1',
  AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_2 = 'AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_2',
  AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_3 = 'AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_3',
  AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_1 = 'AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_1',
  AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_2 = 'AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_2',
  AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_3 = 'AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_3',
  AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_1 = 'AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_1',
  AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_2 = 'AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_2',
  AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_3 = 'AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_3',
  AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_1 = 'AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_1',
  AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_2 = 'AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_2',
  AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_3 = 'AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_3',
  AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_1 = 'AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_1',
  AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_2 = 'AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_2',
  AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_3 = 'AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_3',
  AUTHENTICATION_SIGNUP_SUCCESS_CONTENT = 'AUTHENTICATION_SIGNUP_SUCCESS_CONTENT',
  AUTHENTICATION_SIGNUP_SUCCESS_DEFAULT_FOOTER = 'AUTHENTICATION_SIGNUP_SUCCESS_DEFAULT_FOOTER',
  AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST_FOOTER = 'AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST_FOOTER',
  AUTHENTICATION_SIGNUP_SUCCESS_FOOTER_BUTTON = 'AUTHENTICATION_SIGNUP_SUCCESS_FOOTER_BUTTON',
  AUTHENTICATION_SIGNUP_PRIVACY_AND_TERMS = 'AUTHENTICATION_SIGNUP_PRIVACY_AND_TERMS',
  AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_TITLE = 'AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_TITLE',
  AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_CAPTION = 'AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_CAPTION',
  AUTHENTICATION_SIGNUP_SUCCESS_CHECKOUT = 'AUTHENTICATION_SIGNUP_SUCCESS_CHECKOUT',
  AUTHENTICATION_SIGNUP_SUCCESS_NAVBAR = 'AUTHENTICATION_SIGNUP_SUCCESS_NAVBAR',
  AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST = 'AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST',
  AUTHENTICATION_SIGNUP_SUCCESS_CAR_ALERTS = 'AUTHENTICATION_SIGNUP_SUCCESS_CAR_ALERTS',
  AUTHENTICATION_SIGNUP_SUCCESS_PERSONALISED_QUOTE = 'AUTHENTICATION_SIGNUP_SUCCESS_PERSONALISED_QUOTE',
  AUTHENTICATION_SIGNUP_SUCCESS_BUTTON = 'AUTHENTICATION_SIGNUP_SUCCESS_BUTTON',
  AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_TITLE = 'AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_TITLE',
  AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_SUBTITLE = 'AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_SUBTITLE',
  //
  //
  // Verify phone number microcopy
  //
  //
  VERIFY_PHONE_NUMBER_FORM_PHONE_TITLE = 'VERIFY_PHONE_NUMBER_FORM_PHONE_TITLE',
  VERIFY_PHONE_NUMBER_FORM_PHONE_SUBTITLE = 'VERIFY_PHONE_NUMBER_FORM_PHONE_SUBTITLE',
  VERIFY_PHONE_NUMBER_FORM_VERIFY_TITLE = 'VERIFY_PHONE_NUMBER_FORM_VERIFY_TITLE',
  VERIFY_PHONE_NUMBER_FORM_VERIFY_PHONE_SUBTITLE = 'VERIFY_PHONE_NUMBER_FORM_VERIFY_PHONE_SUBTITLE',
  //
  //
  // Set location microcopy
  //
  //
  DELIVERY_LOCATION_POPUP_TITLE_START = 'DELIVERY_LOCATION_POPUP_TITLE_START',
  DELIVERY_LOCATION_POPUP_ENTER_LOCATION_FIELD = 'DELIVERY_LOCATION_POPUP_ENTER_LOCATION_FIELD',
  DELIVERY_LOCATION_POPUP_ENTER_LOCATION_HELPER = 'DELIVERY_LOCATION_POPUP_ENTER_LOCATION_HELPER',
  DELIVERY_LOCATION_POPUP_ENTER_LOCATION_ERROR = 'DELIVERY_LOCATION_POPUP_ENTER_LOCATION_ERROR',
  DELIVERY_LOCATION_POPUP_TITLE_DELIVERY_COLLECTION = 'DELIVERY_LOCATION_POPUP_TITLE_DELIVERY_COLLECTION',
  DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_TITLE = 'DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_TITLE',
  DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_TITLE = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_TITLE',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_FIELD = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_FIELD',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_ERROR = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_ERROR',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_BUTTON = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_BUTTON',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_SUCCESS = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_SUCCESS',
  DELIVERY_LOCATION_POPUP_COLLECTION_TITLE = 'DELIVERY_LOCATION_POPUP_COLLECTION_TITLE',
  DELIVERY_LOCATION_POPUP_COLLECTION_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_COLLECTION_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_FOOTER = 'DELIVERY_LOCATION_POPUP_FOOTER',
  DELIVERY_LOCATION_POPUP_CONFIRM = 'DELIVERY_LOCATION_POPUP_CONFIRM',
  DELIVERY_LOCATION_POPUP_OUT_OF_AREA_SUBTITLE = 'DELIVERY_LOCATION_POPUP_OUT_OF_AREA_SUBTITLE',
  DELIVERY_LOCATION_POPUP_OUT_OF_AREA_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_OUT_OF_AREA_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_OUT_OF_AREA_CALL_US_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_OUT_OF_AREA_CALL_US_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_EDIT_LOCATION = 'DELIVERY_LOCATION_POPUP_EDIT_LOCATION',
  DELIVERY_LOCATION_POPUP_PICKUP_LOCATION = 'DELIVERY_LOCATION_POPUP_PICKUP_LOCATION',
  DELIVERY_LOCATION_DIRECTIONS_BUTTON = 'DELIVERY_LOCATION_DIRECTIONS_BUTTON',
  //
  //
  // Personalized quote microcopy
  //
  //
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_HEADER = 'PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_HEADER',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_DESCRIPTION = 'PERSONALIZED_QUOTE_LOAN_DETAILS_DESCRIPTION',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_CTA = 'PERSONALIZED_QUOTE_LOAN_DETAILS_CTA',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_TITLE = 'PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_TITLE',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_DESCRIPTION = 'PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_DESCRIPTION',
  PERSONALIZED_QUOTE_EMAIL_FORM_HEADER = 'PERSONALIZED_QUOTE_EMAIL_FORM_HEADER',
  PERSONALIZED_QUOTE_EMAIL_FORM_CTA = 'PERSONALIZED_QUOTE_EMAIL_FORM_CTA',
  PERSONALIZED_QUOTE_EMAIL_FORM_DESCRIPTION = 'PERSONALIZED_QUOTE_EMAIL_FORM_DESCRIPTION',
  PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_HEADER = 'PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_HEADER',
  PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_CTA = 'PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_CTA',
  PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_DESCRIPTION = 'PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_DESCRIPTION',
  PERSONALIZED_QUOTE_LOAN_TYPE_FORM_HEADER = 'PERSONALIZED_QUOTE_LOAN_TYPE_FORM_HEADER',
  PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA_LOGGED_IN = 'PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA_LOGGED_IN',
  PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA = 'PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA',
  PERSONALIZED_QUOTE_SIGN_IN_FORM_FORGOT_PASSWORD_CTA = 'PERSONALIZED_QUOTE_SIGN_IN_FORM_FORGOT_PASSWORD_CTA',
  PERSONALIZED_QUOTE_FORGOT_PASSWORD_DESCRIPTION = 'PERSONALIZED_QUOTE_FORGOT_PASSWORD_DESCRIPTION',
  PERSONALIZED_QUOTE_FORGOT_PASSWORD_CTA = 'PERSONALIZED_QUOTE_FORGOT_PASSWORD_CTA',
  PERSONALIZED_QUOTE_RESEND_PASSWORD_CTA = 'PERSONALIZED_QUOTE_RESEND_PASSWORD_CTA',
  PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_PERSONAL_HEADER = 'PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_PERSONAL_HEADER',
  PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_BUSINESS_HEADER = 'PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_BUSINESS_HEADER',
  PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_CTA = 'PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_CTA',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FORM_HEADER = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FORM_HEADER',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FAMILY_STATUS_TITLE = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FAMILY_STATUS_TITLE',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_RESIDENTIAL_ADDRESS_TITLE = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_RESIDENTIAL_ADDRESS_TITLE',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_PERSONAL_BUTTON_SUBMIT = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_PERSONAL_BUTTON_SUBMIT',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_BUSINESS_BUTTON_SUBMIT = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_BUSINESS_BUTTON_SUBMIT',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_HEADER = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_HEADER',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_EMPLOYMENT_STATUS_TITLE = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_EMPLOYMENT_STATUS_TITLE',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_RESIDENCY_STATUS_TITLE = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_RESIDENCY_STATUS_TITLE',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_SUBMIT_BUTTON_LABEL = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_SUBMIT_BUTTON_LABEL',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA_NEW_USER = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA_NEW_USER',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_HEADER = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_HEADER',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CHECKBOX = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CHECKBOX',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_DESCRIPTION = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_DESCRIPTION',
  PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION = 'PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION',
  PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION_NO_QUOTES = 'PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION_NO_QUOTES',
  PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_BUTTON_LABEL = 'PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_BUTTON_LABEL',
  PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_NEXT_PAGE_BUTTON_LABEL = 'PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_NEXT_PAGE_BUTTON_LABEL',
  PERSONALIZED_QUOTE_QUOTES_LIST_TITLE = 'PERSONALIZED_QUOTE_QUOTES_LIST_TITLE',
  PERSONALIZED_QUOTE_QUOTES_LIST_DESCRIPTION = 'PERSONALIZED_QUOTE_QUOTES_LIST_DESCRIPTION',
  PERSONALIZED_QUOTE_CONFIRMATION_BUTTON_LABEL = 'PERSONALIZED_QUOTE_CONFIRMATION_BUTTON_LABEL',
  PERSONALIZED_QUOTE_RESULT_CARD_TITLE = 'PERSONALIZED_QUOTE_RESULT_CARD_TITLE',
  PERSONALIZED_QUOTE_RESULT_CARD_DESCRIPTION = 'PERSONALIZED_QUOTE_RESULT_CARD_DESCRIPTION',
  PERSONALIZED_QUOTE_SIGNUP_HEADER = 'PERSONALIZED_QUOTE_SIGNUP_HEADER',
  PERSONALIZED_QUOTE_SIGNUP_HEADER_DESCRIPTION = 'PERSONALIZED_QUOTE_SIGNUP_HEADER_DESCRIPTION',
  PERSONALIZED_QUOTE_SIGNUP_CTA = 'PERSONALIZED_QUOTE_SIGNUP_CTA',
  PERSONALIZED_QUOTE_SIGNUP_CHECKBOX = 'PERSONALIZED_QUOTE_SIGNUP_CHECKBOX',
  PERSONALIZED_QUOTE_SIGNUP_TERM_AND_POLICY = 'PERSONALIZED_QUOTE_SIGNUP_TERM_AND_POLICY',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_DESCRIPTION = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_DESCRIPTION',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_HEADER_BUTTON_LABEL = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_HEADER_BUTTON_LABEL',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_1_CONTENT = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_1_CONTENT',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_2_CONTENT = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_2_CONTENT',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_3_CONTENT = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_3_CONTENT',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_INFORMATION = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_INFORMATION',
  PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA = 'PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA',
  PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA_LABEL = 'PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA_LABEL',
  PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_TITLE = 'PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_TITLE',
  PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_DESCRIPTION = 'PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_DESCRIPTION',
  PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_CTA = 'PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_CTA',
  //
  //
  // Content hub microcopy
  //
  //
  CONTENT_HUB_TITLE = 'CONTENT_HUB_TITLE',
  CONTENT_HUB_NEWSLETTER_TITLE = 'CONTENT_HUB_NEWSLETTER_TITLE',
  CONTENT_HUB_NEWSLETTER_SUBMIT = 'CONTENT_HUB_NEWSLETTER_SUBMIT',
  CONTENT_HUB_NEWSLETTER_SUCCESS = 'CONTENT_HUB_NEWSLETTER_SUCCESS',
  CONTENT_HUB_RELATED_ARTICLES_TITLE = 'CONTENT_HUB_RELATED_ARTICLES_TITLE',
  //
  //
  // CarMatch microcopy
  //
  //
  CARMATCH_START_PAGE = 'CARMATCH_START_PAGE',
  CARMATCH_START_PAGE_CTA = 'CARMATCH_START_PAGE_CTA',
  CARMATCH_RESULTS_HEADER_MULTIPLE_RESULTS = 'CARMATCH_RESULTS_HEADER',
  CARMATCH_RESULTS_HEADER_SINGLE_RESULT = 'CARMATCH_RESULTS_HEADER_SINGLE_RESULT',
  CARMATCH_RESULTS_VIEW_ALL_TITLE = 'CARMATCH_RESULTS_VIEW_ALL_TITLE',
  CARMATCH_RESULTS_VIEW_ALL_DESCRIPTION = 'CARMATCH_RESULTS_VIEW_ALL_DESCRIPTION',
  CARMATCH_RESULTS_VIEW_ALL_CTA = 'CARMATCH_RESULTS_VIEW_ALL_CTA',
  CARMATCH_RESULTS_SEE_AVAILABLE_CTA = 'CARMATCH_RESULTS_SEE_AVAILABLE_CTA',
  CARMATCH_RESULTS_CAROUSEL_TILE_PRICE_DESCRIPTION = 'CARMATCH_RESULTS_CAROUSEL_TILE_PRICE_DESCRIPTION',
}
