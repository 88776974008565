'use client';

import { basicLogger, withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, ReactNode } from 'react';

import { getInitialLdUserGroup } from 'src/general/helpers/getLdUserGroup';
import { AuthStoreProvider, getUserAuthDataFromCookies, useCreateAuthStore } from 'src/stores/authStore';
import { CarStatusStoreProvider, useCreateCarStatusStore } from 'src/stores/carStatusStore';
import { CartStoreProvider, useCreateCartStore } from 'src/stores/cartStore';
import { LiveChatStoreProvider, useCreateLiveChatStore } from 'src/stores/liveChatStore';
import { AuthManager } from 'src/stores/managers/AuthManager';
import { CarStatusManager } from 'src/stores/managers/CarStatusManager';
import { CartManager } from 'src/stores/managers/CartManager';
import { LiveChatManager } from 'src/stores/managers/LiveChatManager';
import { SavedSearchManager } from 'src/stores/managers/SavedSearchManager';
import { WatchListManager } from 'src/stores/managers/WatchListManager';
import { RouterHistoryStoreProvider, useCreateRouterHistoryStore } from 'src/stores/routerHistoryStore';
import { SavedSearchStoreProvider, useCreateSavedSearchStore } from 'src/stores/savedSearchStore';
import { WatchlistStoreProvider, useCreateWatchlistStore } from 'src/stores/watchListStore';

interface Props {
  children: ReactNode;
}

/**
 * Zustand store providers and manager, focussed around Authentication and Snackbar provider
 */
const StoreProvidersInner: FC<Props> = ({ children }) => {
  const createRouterHistoryStore = useCreateRouterHistoryStore({});
  const createAuthStore = useCreateAuthStore({
    userAuthData: getUserAuthDataFromCookies(),
  });
  const createCartStore = useCreateCartStore({});
  const createSavedSearchStore = useCreateSavedSearchStore({});
  const createWatchlistStore = useCreateWatchlistStore({});
  const createLiveChatStore = useCreateLiveChatStore({});
  const createCarStatusStore = useCreateCarStatusStore({});

  return (
    <RouterHistoryStoreProvider createStore={createRouterHistoryStore}>
      <CarStatusStoreProvider createStore={createCarStatusStore}>
        <AuthStoreProvider createStore={createAuthStore}>
          <SavedSearchStoreProvider createStore={createSavedSearchStore}>
            <CartStoreProvider createStore={createCartStore}>
              <WatchlistStoreProvider createStore={createWatchlistStore}>
                <LiveChatStoreProvider createStore={createLiveChatStore}>
                  {/* Store managers */}
                  <CarStatusManager />
                  <AuthManager />
                  <SavedSearchManager />
                  <CartManager />
                  <WatchListManager />
                  <LiveChatManager />
                  {children}
                </LiveChatStoreProvider>
              </WatchlistStoreProvider>
            </CartStoreProvider>
          </SavedSearchStoreProvider>
        </AuthStoreProvider>
      </CarStatusStoreProvider>
    </RouterHistoryStoreProvider>
  );
};

export const StoreProviders = withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!,
  context: {
    kind: 'user',
    key: getInitialLdUserGroup(),
  },
  options: {
    logger: basicLogger({ level: process.env.APP_ENVIRONMENT === 'production' ? 'warn' : 'info' }),
  },
})(StoreProvidersInner as FC) as FC<Props>;
