/**
 * Helper function to get the website's base URL
 */
export const getBaseUrl = (): string => {
  if (process.env.BASE_URL) {
    return `${process.env.BASE_URL}`;
  }
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }
  return `http://localhost:${process.env.PORT ?? 3000}`;
};
