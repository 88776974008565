import { SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import classes from './Icon.module.scss';

export const Icon: FC<ComponentProps<typeof SvgIcon>> = ({ children, className, ...props }) => (
  <svg
    {...props}
    aria-hidden="true"
    className={classNames(
      'icon',
      'MuiSvgIcon-root', // class name kept for backward compatibility purposes
      classes.root,
      className,
    )}
  >
    {children}
  </svg>
);
