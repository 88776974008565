import qs from 'query-string';

import {
  PageProductListingFilters,
  PageProductListingOptions,
} from 'src/data/Contentful/ContentfulGetPageProductListing.types';

import { plpStoreFiltersToUrlParams } from './plpStoreFiltersToUrlParams';

const filterParamsNames = [
  'makeModel[]',
  'search',
  'priceCashMin',
  'priceCashMax',
  'priceFinMin',
  'priceFinMax',
  'yearMin',
  'yearMax',
  'kmMin',
  'kmMax',
  'enginePowerMin',
  'enginePowerMax',
  'fuelConsumptionMin',
  'fuelConsumptionMax',
  'transmission[]',
  'fuelType[]',
  'bodyType[]',
  'driveType[]',
  'highlightedFeatures[]',
  'seats[]',
  'doors[]',
  'colour[]',
  'co2Emissions',
  'ancapSafetyRating',
  'state',
  'promotionTags[]',
  'sortBy',
  'page',
];

/**
 * Transform store filter into a URL.
 * Takes into account PLP listing pages mappings
 */
export const getPlpUrlFromStoreFilters = (
  storeFilters: PageProductListingFilters,
  storeListingOptions: PageProductListingOptions = {},
  plpPagesMap?: Map<string, string>,
  /** Raw search params to extract the whitelisted PLP params such as UTM */
  searchParams?: string,
) => {
  const whitelistedParams = extractWhitelistedParams(searchParams);
  const urlParams = plpStoreFiltersToUrlParams(storeFilters, storeListingOptions, whitelistedParams);
  /** The filter URL params without the listing option */
  const urlParamsNoOptions = plpStoreFiltersToUrlParams(storeFilters, {});
  const basePath = '/used-cars';
  let resultUrl = urlParams ? `${basePath}?${urlParams}` : basePath;
  const resultUrlNoOptions = urlParamsNoOptions ? `${basePath}?${urlParamsNoOptions}` : basePath;
  if (plpPagesMap && plpPagesMap.has(resultUrlNoOptions)) {
    resultUrl = plpPagesMap.get(resultUrlNoOptions)!;
    const urlParamsNoFilters = plpStoreFiltersToUrlParams({}, storeListingOptions, whitelistedParams);
    if (urlParamsNoFilters) {
      resultUrl += `?${urlParamsNoFilters}`;
    }
  }
  return resultUrl;
};

const extractWhitelistedParams = (searchParams: string = '') => {
  const result: Record<string, any> = {};
  const parsedParams = qs.parse(searchParams);

  // Save each parameter that isn't in filterParamsNames
  Object.keys(parsedParams).forEach((paramName) => {
    if (filterParamsNames.indexOf(paramName) < 0) {
      result[paramName] = parsedParams[paramName];
    }
  });

  return result;
};
