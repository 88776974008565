export default {
  0: '104px',
  1: '80px',
  2: '68px',
  3: '56px',
  4: '40px',
  5: '32px',
  6: '28px',
  7: '24px',
  8: '20px',
  9: '18px',
  10: '16px',
  11: '36px',
};
