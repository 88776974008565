import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { COOKIE_USER_TOKEN } from 'src/constants';

const cookies = new Cookies();

export const carmaClientLegacy = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_API_URL,
});

const onResponseError = (error: AxiosError) => {
  if (process.env.DEBUG) {
    // eslint-disable-next-line no-console
    console.log('Response Error:', JSON.stringify(error, null, 2));
  }

  return Promise.reject(error);
};

carmaClientLegacy.interceptors.request.use(
  (config) => {
    if (process.env.DEBUG) {
      // eslint-disable-next-line no-console
      console.log('Starting Request', JSON.stringify(config, null, 2));
    }
    const token = cookies.get(COOKIE_USER_TOKEN);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(new Error(error)),
);

carmaClientLegacy.interceptors.response.use((response) => {
  if (process.env.DEBUG) {
    // eslint-disable-next-line no-console
    console.log('Response:', JSON.stringify(response, null, 2));
  }
  return response;
}, onResponseError);
