import { enableMapSet, produce } from 'immer';

import { WatchlistItemResponse } from 'src/data/WatchListApi/WatchlistApi.types';
import { storeFactory } from 'src/stores/storeFactory';

/**
 *
 * Store to manage watchlist
 * Follows Zustand store implementation with Next.JS example here:
 * https://github.com/vercel/next.js/tree/canary/examples/with-zustand
 *
 */
enableMapSet();

export type Watchlist = Map<string, string>;

interface WatchlistStoreStateData {
  isInitialLoading: boolean;
  isLoading: boolean;
  watchlist: Watchlist;
}

interface WatchlistStoreStateFunctions {
  getWatchlist: () => Watchlist;
  hasWatchlistItem: (sku: string) => boolean;
  setWatchlistData: (watchlistItems: WatchlistItemResponse[] | null) => void;
  setWatchlistLoading: () => void;
  emptyWatchlist: () => void;
}

const {
  store: watchlistStore,
  StoreProvider: WatchlistStoreProvider,
  useCreateStore: useCreateWatchlistStore,
} = storeFactory<WatchlistStoreStateData, WatchlistStoreStateFunctions>(
  () => {
    const watchlist: Watchlist = new Map<string, string>();

    return {
      isInitialLoading: true,
      isLoading: true,
      watchlist,
    };
  },
  (set, get) => ({
    setWatchlistLoading: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.isLoading = true;
        }),
      );
    },
    setWatchlistData: (watchlistItems: WatchlistItemResponse[] | null) => {
      set((state) =>
        produce(state, (draft) => {
          draft.watchlist = new Map<string, string>(watchlistItems!.map((item) => [item.sku, item.created_at]));
          draft.isLoading = false;
          draft.isInitialLoading = false;
        }),
      );
    },
    emptyWatchlist: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.watchlist = new Map<string, string>();
        }),
      );
    },
    getWatchlist: () => produce(get().watchlist, () => {}),
    hasWatchlistItem: (sku: string) => get().watchlist.has(sku),
  }),
);

export { WatchlistStoreProvider, useCreateWatchlistStore, watchlistStore };
