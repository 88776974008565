import { OrdersResponse } from 'src/types/CataloguePage.types';

import { carmaClientLegacy } from './instance/carmaClientLegacy';

export const getOrder = (id: string) => carmaClientLegacy.get(`/me/orders/${id}`);

export const getOrders = () => carmaClientLegacy.get<OrdersResponse[]>('/me/orders');

export const getOrdersIgnoreError = () =>
  carmaClientLegacy
    .get<OrdersResponse[]>('/me/orders')
    .then((response) => response.data)
    .catch((_error) => null);
