import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconCheck: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.566 5.333 6.95 10.95a.4.4 0 0 1-.566 0L3.434 8 4 7.434l2.667 2.667L12 4.768l.566.565Z"
    />
  </Icon>
);
