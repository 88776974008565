import { FilterValues } from './CataloguePage.types';

export const enum SortValues {
  Recommended = 'recommended',
  Added = 'added',
  LowestPrice = 'lowestPrice',
  HighestPrice = 'highestPrice',
  LowestKm = 'lowestKm',
  HighestKm = 'highestKm',
  Newest = 'newest',
  Oldest = 'oldest',
}

export const sortValuesList: { [key in SortValues]: string } = {
  [SortValues.Recommended]: 'Recommended',
  [SortValues.Added]: 'Just added',
  [SortValues.LowestPrice]: 'Lowest price',
  [SortValues.HighestPrice]: 'Highest price',
  [SortValues.LowestKm]: 'Lowest kms',
  [SortValues.HighestKm]: 'Highest kms',
  [SortValues.Newest]: 'Newest age',
  [SortValues.Oldest]: 'Oldest age',
};

export const sortValuesListKeys = Object.keys(sortValuesList) as SortValues[];

export interface FilterListPayload {
  makeAndModel: Model[];
  seats: CarFilterDataRow[];
  doors: CarFilterDataRow[];
  transmission: CarFilterDataRow[];
  fuelType: CarFilterDataRow[];
  bodyType: BodyType[];
  driveType: CarFilterDataRow[];
  highlightedFeatures: CarFilterDataRow[];
  colour: CarFilterDataRow[];
  ancapSafetyRating: CarFilterDataRow[];
  year: CarFilterDataRow[];
  kilometers: CarFilterDataRow[];
  price: Price;
  co2Emissions: CarFilterDataRow[];
}

export type PlpFilterParamBooleanKeys = {
  [key: string]: true;
};

export type PlpFilterParamValue = { value: string };

export type PlpFilterParamPrice = {
  min: string;
  max: string;
  priceType: 'cash' | 'finance';
};

export interface PlpListingParams {
  sortBy?: SortValues;
  page?: number;
  pageSize?: number;
}

export interface PlpFilterParamRange {
  min: string;
  max: string;
}
export interface PlpFilterParams extends PlpListingParams {
  makeAndModel?: Model[];
  keyword?: { search: string };
  price?: PlpFilterParamPrice;
  year?: PlpFilterParamRange;
  kilometers?: PlpFilterParamRange;
  enginePower?: PlpFilterParamRange;
  fuelConsumption?: PlpFilterParamRange;
  transmission?: PlpFilterParamBooleanKeys;
  fuelType?: PlpFilterParamBooleanKeys;
  bodyType?: BodyType[];
  bodySize?: BodySize[];
  driveType?: PlpFilterParamBooleanKeys;
  highlightedFeatures?: PlpFilterParamBooleanKeys;
  seats?: PlpFilterParamBooleanKeys;
  doors?: PlpFilterParamBooleanKeys;
  colour?: PlpFilterParamBooleanKeys;
  co2Emissions?: PlpFilterParamValue;
  ancapSafetyRating?: PlpFilterParamValue;
  state?: string;
  promotionTags?: string;
}

/**
 * Filters for the Product API query
 */
export interface PlpFilterQueryParams {
  filters: FiltersPayloadRow[];
  page: number;
  pageSize: number;
  sort: SortValues;
}

/**
 * Following fields are deprecated: value, name
 */
export interface CarFilterDataRow {
  key: string | number;
  label: string;
  value?: string | number;
  name?: string;
  quantity?: number | string;
  values?: number[];
  icon?: string;
  code?: string;
}

export interface Model {
  key: string;
  name?: string;
  label: string;
  quantity: number;
  models?: Model[];
  parentKey?: string;
}

export interface BodySize {
  key: string;
  label: string;
  quantity?: number;
}

export interface BodyType {
  key: string;
  label: string;
  quantity: number;
  sizes?: BodySize[];
}

export interface Price {
  cashList: CarFilterDataRow[];
  financeList: CarFilterDataRow[];
}

export interface FormFilterValues<T> {
  [Key: string | number]: T;
}

export interface FiltersPayloadRow {
  filter: FilterValues;
  value: string | number;
}
