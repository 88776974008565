'use client';

import { SnackbarProvider } from 'notistack';
import { FC, ReactNode } from 'react';

import { IconCheck } from 'src/general/Icons/IconCheck';
import { IconClose } from 'src/general/Icons/IconClose';
import classes from './NotificationProvider.module.scss';

interface Props {
  children: ReactNode;
}

export const NotificationProvider: FC<Props> = ({ children }) => (
  <SnackbarProvider
    iconVariant={{
      success: <IconCheck />,
      info: null,
      error: <IconClose />,
    }}
    autoHideDuration={3000}
    preventDuplicate
    className={classes.notification}
  >
    {children}
  </SnackbarProvider>
);
