import { captureException } from '@sentry/nextjs';
import { Component, ErrorInfo, ReactNode } from 'react';

import { BADGE } from 'src/general/components/BadgeIllustration/BadgeIllustration.type';
import { WholePageMessage } from 'src/general/sections/WholePageMessage/WholePageMessage';

interface Props {
  children: ReactNode;
  errorComponent?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Intentionally display the error in the console for debug
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
    captureException(error);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.errorComponent ? (
        this.props.errorComponent
      ) : (
        <WholePageMessage
          buttonLabel="Back to home"
          buttonLink="/"
          title="Oops, looks like something went wrong"
          iconSrc={BADGE.CARMA_TOUCH}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
