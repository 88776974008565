import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';

import { Button } from '../Button/Button';

export const PreviewSnackbar: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar('Currently in preview mode', {
      variant: 'warning',
      autoHideDuration: null,
      action: () => (
        <Button href="/api/exit-preview" variant="secondary" size="small">
          Exit preview
        </Button>
      ),
    });
  }, []);
  return <></>;
};
