export default {
  0: 12,
  1: 14,
  2: 15,
  3: 17,
  4: 20,
  5: 23,
  6: 26,
  7: 32,
  8: 44,
  9: 60,
  10: 72,
  11: 96,
};
