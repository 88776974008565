import { processKilometers, processPriceString } from 'src/general/helpers/processProductApiData';
import { BaseCarData } from 'src/types/Car.types';
import { ProductListDataRow } from 'src/types/CataloguePage.types';
import { ProductDetailsPayload } from 'src/types/ProductDetail.type';

export const productDetailPayloadToBaseCarData = (
  productDetail: ProductDetailsPayload | ProductListDataRow,
): BaseCarData => ({
  sku: productDetail.sku,
  state: 'state' in productDetail ? productDetail.state : productDetail.status,
  variant: productDetail.variant,
  model: [productDetail.buildYear, productDetail.make, productDetail.model].join(' '),
  makeModel: [productDetail.make, productDetail.model],
  imageUrl: 'card' in productDetail ? productDetail.card : productDetail.images.card.url,
  kilometers: processKilometers(productDetail.kilometres),
  fuelType: productDetail.fuelType,
  transmission: productDetail.transmission,
  bodyType: productDetail.body,
  colour: productDetail.colour,
  ancapRating: productDetail.ancapRating,
  price: processPriceString(productDetail.price),
  gstPrice: processPriceString(productDetail.price) / 11,
  dutyPrice: processPriceString(productDetail.dutyPrice),
  buildYear: productDetail.buildYear,
  promotionTags: productDetail.search_keywords,
  watchers: productDetail.watchers,
});
