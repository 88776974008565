import block from './block';
import borderRadius from './borderRadius';
import boxShadow from './boxShadow';
import color from './color';
import styleGuide from './styleGuide';
import typography from './typography';

function transform(value) {
  return value <= 1 ? `${value * 100}%` : value;
}
const theme = {
  blocking: block.blocking,
  transform,
  boxShadow,
  estimate: styleGuide.estimate,
  typography,
  borderRadius,
  breakpoints: {
    keys: ['xs', 'mxs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    values: {
      xs: 0,
      mxs: 360,
      sm: 600,
      md: 840,
      lg: 1280,
      xl: 1440,
      xxl: 1920,
    },
  },
  palette: {
    common: {
      black: color.opacity.dark['high-emphasis'],
      white: color.opacity.light['high-emphasis'],
    },
    background: {
      default: '#fff',
    },
    shadows: ['none'],
    primary: {
      main: color['ui-primary'][600],
      contrastText: color.neutral['0'],
    },
    secondary: {
      main: color['brand-primary'][100],
      contrastText: color.neutral['0'],
    },
    grey: color.neutral,
    text: {
      primary: color.opacity.dark['high-emphasis'],
    },
    link: {
      default: color.action.text.link.default,
      hover: color.action.text.link.hover,
      'on-press': color.action.text.link['on-press'],
    },
    action: {
      link: {
        default: color['ui-primary'][600],
        hover: color['ui-primary'][700],
        'on-press': color['ui-primary'][800],
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
};

export default theme;
