export default {
  1: `inset 0px -1px 0px #e0e0e0`,
  2: '0px 10.625px 13.2812px rgba(0, 0, 0, 0.12), 0px 0px 2.65625px rgba(0, 0, 0, 0.08)',
  3: '0px 2px 1px -1px rgba(62, 60, 94, 0.1), 0px 3px 3px -2px rgba(150, 147, 189, 0.4)',
  4: '0 0 0 rgba(0, 0, 0, 0.04), 0 2px 8px rgba(0, 0, 0, 0.16)',
  5: '0px 12px 14px rgba(0, 0, 0, 0.16), 0px 0px 6px rgba(0, 0, 0, 0.16)',
  6: '0px 20px 24px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.08)',
  7: '0px 0px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.16)',
  elevation: '0px 1px 1px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.16)',
};
