import { enableMapSet, produce } from 'immer';
import { useLayoutEffect } from 'react';
import { create, StoreApi } from 'zustand';
import { createContext } from 'zustand-utils';

/**
 *
 * Store to manage routerHistory
 * Follows Zustand store implementation with Next.JS example here:
 * https://github.com/vercel/next.js/tree/canary/examples/with-zustand
 *
 */
enableMapSet();

interface RouterHistoryStoreStateData {
  previousUrl: string | null;
}

export interface RouterHistoryStoreState extends RouterHistoryStoreStateData {
  set: (fn: (state: RouterHistoryStoreState) => void) => void;
  setPreviousUrl: (url: string) => void;
  getPreviousUrl: (fallbackUrl: string) => string;
}

let store: StoreApi<RouterHistoryStoreState> | undefined;
const routerHistoryStoreContext = createContext<StoreApi<RouterHistoryStoreState>>();

const getDefaultInitialState: () => RouterHistoryStoreStateData = () => ({
  previousUrl: null,
});

const initialiseRouterHistoryStore = (preloadedState: Partial<RouterHistoryStoreStateData> = {}) =>
  create<RouterHistoryStoreState>((set, get) => ({
    ...getDefaultInitialState(),
    ...preloadedState,
    set: (fn) => set(produce(fn)),
    setPreviousUrl: (url) => {
      set((state) =>
        produce(state, (draft) => {
          draft.previousUrl = url;
        }),
      );
    },
    getPreviousUrl: (fallbackUrl) => get().previousUrl ?? fallbackUrl,
  }));

export const RouterHistoryStoreProvider = routerHistoryStoreContext.Provider;

export const routerHistoryStore = routerHistoryStoreContext.useStore;

export const useCreateRouterHistoryStore = (serverInitialState: Partial<RouterHistoryStoreStateData>) => {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initialiseRouterHistoryStore(serverInitialState);
  }
  // End of server side code

  // Client side code:
  // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
  const isReusingStore = Boolean(store);
  store = store ?? initialiseRouterHistoryStore(serverInitialState);
  // When next.js re-renders _app while re-using an older store, then replace current state with
  // the new state (in the next render cycle).
  // (Why next render cycle? Because react cannot re-render while a render is already in progress.
  // i.e. we cannot do a setState() as that will initiate a re-render)
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment (i.e. client or server)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
    // states on CSR page navigation or not. I have chosen not to, but if you choose to,
    // then add `serverInitialState = getDefaultInitialState()` here.
    if (serverInitialState && isReusingStore) {
      store!.setState(
        {
          // re-use functions from existing store
          ...store!.getState(),
          // but reset all other properties.
          ...serverInitialState,
        },
        true, // replace states, rather than shallow merging
      );
    }
  });

  return () => store!;
};
