import { AxiosResponse } from 'axios';

import { carmaClientLegacy } from 'src/services/instance/carmaClientLegacy';

import { ProfileApiResponse } from './ProfileApi.types';

/**
 * Request a quote
 */
export const getProfile = (): Promise<ProfileApiResponse> =>
  carmaClientLegacy.get<any, AxiosResponse<ProfileApiResponse>>(`/me/profile`).then((body) => body.data);
