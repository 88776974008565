'use client';

import dynamic from 'next/dynamic';
import { FC } from 'react';

const WebsiteGlobals = dynamic(() => import('src/general/components/WebsiteGlobals/WebsiteGlobals'), { ssr: false });
const ScrollWatcher = dynamic(() => import('src/general/components/ScrollWatcher/ScrollWatcher'), { ssr: false });
const AnalyticsNavigation = dynamic(
  () => import('src/general/components/Analytics/AnalyticsNavigation').then((mod) => mod.AnalyticsNavigation),
  { ssr: false },
);
const ColourSwitcher = dynamic(
  () => import('src/general/components/ColourSwitcher/ColourSwitcher').then((mod) => mod.ColourSwitcher),
  { ssr: false },
);

/**
 * Load Website helpers such as mini-CDP, Website globals, scroll watcher
 */
export const WebsiteHelpers: FC = () => (
  <>
    <AnalyticsNavigation />
    <WebsiteGlobals />
    <ScrollWatcher />
    <ColourSwitcher />
  </>
);
