import { isEmpty } from 'lodash';

/**
 * Ensure a phone number is formatted following the E.164 format
 * https://en.wikipedia.org/wiki/E.164
 *
 * Please note: Assumption is we'll always deal with Australian phone numbers
 */
export const getPhoneNumberE164 = (phone: string) => {
  if (!isEmpty(phone)) {
    return phone.replace(/^0/, '+61').replaceAll(' ', '');
  }
  return phone;
};
